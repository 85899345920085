import React from 'react'
import PropTypes from 'prop-types'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  Box,
  Button,
  Typography,
  Checkbox,
  Chip
} from '@mui/material'

const Summary = (props) => {
  const { isExpanded, checked, indeterminate, itemsNo, label, onCheckbox, onExpand } = props

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: !isExpanded ? 'common.white' : 'background.100',
        borderRadius: '2px',
        minHeight: 40,
        height: 40,
        '&:hover': {
          backgroundColor: 'background.100'
        },
        px: 1
      }}>
      <Checkbox
        color='secondary'
        checked={checked}
        indeterminate={indeterminate}
        onChange={onCheckbox}
      />
      <Button
        onClick={onExpand}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          textAlign : 'left',
          p: 0
        }}
      >
        <Typography
          component='p'
          variant='subtitle1'
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            mr: 2
          }}
        >
          {label}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {itemsNo > 1 && (
            <Chip label={itemsNo} sx={{ mr: 2.375 }} />
          )}
          <ArrowDropDownIcon
            sx={{
              color: 'grey.300',
              ...(isExpanded ? { transform: 'rotate(180deg)' } : {})
            }}
          />
        </Box>
      </Button>
    </Box>
  )
}

Summary.propTypes = {
  isExpanded: PropTypes.bool,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  itemsNo: PropTypes.number,
  label: PropTypes.string,
  onCheckbox: PropTypes.func,
  onExpand: PropTypes.func,
}

export default Summary