import React, { useEffect, useState, useRef, useContext } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import get from 'lodash/get'
import { Grid, Box } from '@mui/material'

import GlobalStore from '../../utils/store'
import PageLayout from '../containers/PageLayout'
import LoadingMergePredictorRow from './LoadingMergePredictorRow'
import { MergePredictorRow } from './mergeComponents'
import { PageContext, fetchApi, parseObjToQueryParams } from '../../utils/helpers'
import { PAGES, PREDICTORS } from '../../constants'

const MergePredictors = () => {
  const {
    setPage,
    setActionBarProperties,
    actionBarState: { disableAction },
    handleErrorMsg
  } = useContext(PageContext)
  const [selected, setSelected] = useState(null)
  const [results, setResults] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const {
    id,
    languages,
    name,
    view
  } = get(GlobalStore?.getLocal(), PAGES.PREDICTOR_MERGE, null) || {}
  const resultsRef = useRef(results)
  const selectedRef = useRef(selected)
  const prevPredictorRef = useRef({ id, languages, name, view })
  let mounted = useRef(null)

  useEffect(() => {
    fetchPredictors(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (!mounted?.current) {
      setActionBarProperties({
        showBar: true,
        onCancel,
        onAction,
        cancelLabel: 'Cancel',
        actionLabel: !!id ? 'Continue to Configuration' : 'Continue to Second Predictor',
        disableAction: !selected?.length
      })
      mounted.current = true
    } else {
      const disableActionState = !selected
      const actionLabel = !!id ? 'Continue to Configuration' : 'Continue to Second Predictor'

      if (disableActionState !== disableAction) {
        setActionBarProperties({
          actionLabel,
          disableAction: disableActionState
        })
      }
    }

    if (id) {
      prevPredictorRef.current = { id, languages, name, view }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, id])

  const onCancel = () => {
    if (prevPredictorRef?.current?.view) {
      GlobalStore.setData({ id: prevPredictorRef?.current?.id }, PAGES.PREDICTOR_VIEW)
    }
    GlobalStore.setData(null, PAGES.PREDICTOR_MERGE)
    setPage(prevPredictorRef?.current?.view ? PAGES.PREDICTOR_VIEW : PAGES.MAIN)
    setActionBarProperties({ showBar: false })
  }

  const onAction = () => {
    const selectedPredictor = resultsRef?.current?.find(item => item?.id === selectedRef?.current)

    if (prevPredictorRef?.current?.id) {
      let predictorsToMerge = [prevPredictorRef?.current, selectedPredictor]

      GlobalStore.setData({
        language: prevPredictorRef?.current?.languages?.length > 0
          ? prevPredictorRef?.current?.languages[0] : 'noLang',
        predictors: predictorsToMerge,
        view: prevPredictorRef?.current?.view
      }, PAGES.PREDICTOR_MERGE_CONFIGURATOR)
      GlobalStore.setData(null, PAGES.PREDICTOR_MERGE)
      setPage(PAGES.PREDICTOR_MERGE_CONFIGURATOR)
      setSelected(null)
    } else {
      GlobalStore.setData(selectedPredictor, PAGES.PREDICTOR_MERGE)
      setPage(PAGES.PREDICTOR_MERGE)
      setSelected(null)
    }
  }

  const fetchPredictors = async (page = 1) => {
    const loadMore = page > 1

    const params = {
      page,
      ...(languages ? { langs: languages } : {})
    }

    const result = await fetchApi(`${PREDICTORS}${parseObjToQueryParams(params)}`)
    if (!result?.error) {
      const data = loadMore ? [...(results || []), ...(result?.results || [])] : result?.results
      setResults(data)
      resultsRef.current = data
      setHasMore(!!result?.next)
    } else {
      handleErrorMsg(result?.error)
    }
  }

  const handleSelected = (e) => {
    const { value } = e?.target || e

    setSelected(value)
    selectedRef.current = value
  }

  return (
    <PageLayout
      title={!!id ? 'Merge with...' : 'Select First Predictor'}
      subtitle={!!id
        ? `Which predictor do you want to merge "${name || '-'}" with?`
        : 'Select the first predictor to be merged.'
      }
    >
      <Grid
        container
        sx={{ width: '100%', px: 2.375, py: 2 }}
      >
        {
          results && results?.length ? (
            <InfiniteScroll
              pageStart={1}
              style={{ width: '100%' }}
              loadMore={fetchPredictors}
              hasMore={hasMore}
              loader={
                <Box key={`loader-infinite-scroll`} sx={theme => ({width: '100%', padding: theme.spacing(0, 2)})}>
                  <LoadingMergePredictorRow/>
                </Box>
              }
            >
              {
                results
                  .filter(el => el?.id !== id)
                  .map((el, idx) => (
                    <Grid
                      item
                      id={`result-element-${idx}`}
                      key={`result-element-${idx}`}
                      xs={12}
                    >
                      <MergePredictorRow
                        {...el}
                        selected={selected}
                        onChange={handleSelected}
                      />
                    </Grid>
                  ))
              }
            </InfiniteScroll>
          ) : null
        }
      </Grid>
    </PageLayout>
  )
}

export default MergePredictors