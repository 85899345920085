import React, { useContext } from 'react'
import {
  Box,
  Toolbar,
  Typography,
  Container,
  Button
} from '@mui/material'

import { PageContext } from '../../utils/helpers'
import { customSecondaryBtn } from '../../constants'

const ActionBar = () => {
  const { actionBarState } = useContext(PageContext)
  const {
    onCancel,
    onAction,
    onOptionalAction,
    cancelLabel,
    actionLabel,
    optionalLabel,
    infoText,
    disableAction
  } = actionBarState || {}

  return (
    <Container maxWidth='xl' sx={{
      '&.MuiContainer-root': {
        pl: 5,
        pr: 5,
        m: 0,
        height: '100%',
        maxWidth: '100%'
      }
    }}>
      <Toolbar disableGutters sx={{
        display: 'flex',
        justifyContent: 'space-between',
        '&.MuiToolbar-root': {
          height: '100%'
        }
      }}>
        <Button
          sx={customSecondaryBtn}
          variant='contained'
          onClick={onCancel}
        >
          {cancelLabel}
        </Button>
        <Box sx={{ flexGrow: 0, display: 'flex' }}>
          {infoText && (
            <Typography
              variant='subtitle2'
              component='p'
              sx={{
                color: 'common.white',
                alignSelf: 'center',
                mr: 2,
                ml: 2,
                wordBreak: 'break-word'
              }}
            >
              {infoText}
            </Typography>
          )}
          {optionalLabel && onOptionalAction && (
            <Button
              sx={{ mr: 1.5, ...customSecondaryBtn }}
              variant='contained'
              onClick={onOptionalAction}
            >
              {optionalLabel}
            </Button>)}
          <Button
            disabled={disableAction}
            variant='contained'
            color='primary'
            onClick={onAction}
          >
            {actionLabel}
          </Button>
        </Box>
      </Toolbar>
    </Container>
  )
}

export default ActionBar
