import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { InputBase, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

import { emptyFn } from '../../utils/helpers'

const SearchContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  marginLeft: 0,
  borderRadius: 2,
  border: `1px solid ${theme.palette.grey[400]}`,
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    borderColor: theme.palette.grey[300]
  },
  '&:focus': {
    borderColor: theme.palette.grey[300],
  },
  '&:focus-visible': {
    borderColor: theme.palette.grey[300],
  },
}))

const SearchIconWrapper = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(0, 1, 0, 1.5),
  padding: 0,
  height: '100%',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.secondary.main
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.secondary.main,
  '&.MuiInputBase-root': {
    width: '100%'
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    width: '100%',
    outline: 0,
    '&:hover': {
      outline: 0
    },
    '&:focus': {
      outline: 0
    },
    '&:focus-visible': {
      outline: 0
    },
    '&::placeholder': {
      color: theme.palette.grey[300]
    }
  },
}))

const Search = (props) => {
  const { onChange, resultsNo, sx } = props
  const [value, setValue] = useState('')
  let timeout = useRef(null)

  useEffect(() => {
    window && window.addEventListener('keypress', handleEnter)

    return () => {
      window && window.removeEventListener('keypress', handleEnter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      onChange(value)
    }
  }

  const handleClear = () => {
    setValue('')
    onChange('')
  }

  const handleChange = (e) => {
    const { value: val } = e?.target || e
    e.preventDefault()
    e.stopPropagation()
    setValue(val)

    if (window) {
      clearTimeout(timeout?.current)
      timeout.current = window.setTimeout(() => {
        onChange(val)
      }, 400)
    }
  }

  return (
    <SearchContainer {...(sx ? { sx } : {})}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder={`Search ${resultsNo} samples...`}
        value={value}
        onChange={handleChange}
      />
      {value?.length ? (
        <IconButton
          onClick={handleClear}
          sx={{
            p: 0.25,
            position: 'absolute',
            right: 11
          }}
        >
          <CloseIcon
            fontSize='small'
            sx={{ color: 'grey.300' }}/>
        </IconButton>
      ) : null}
    </SearchContainer>
  )
}

Search.propTypes = {
  onChange: PropTypes.func,
  resultsNo: PropTypes.number,
  sx: PropTypes.object
}

Search.defaultProps = {
  onChange: emptyFn,
  resultsNo: 0
}

export default Search