export const crawlTypes = [
  {
    value: 'broad',
    title: 'Broad',
    subtitle: 'Scans a broad range of domains organically.'
  },
  {
    value: 'specific',
    title: 'Specific',
    subtitle: 'Scans only domains belonging to your input URLs. '
  }
]

export const timeLimit = [
  {
    label: '1 minute',
    value: 1
  },
  {
    label: '15 minutes',
    value: 15
  },
  {
    label: '30 minutes',
    value: 30
  },
  {
    label: '1 hour',
    value: 60
  },
  {
    label: '2 hours',
    value: 120
  },
  {
    label: '4 hours',
    value: 240
  },
  {
    label: '6 hours',
    value: 360
  },
  {
    label: '12 hours',
    value: 720
  },
  {
    label: '1 day',
    value: 1440
  }
]

export const dataLimit = [
  {
    label: 'No limit',
    value: 'noLimit'
  },
  {
    label: '1 MB',
    value: 1
  },
  {
    label: '5 MB',
    value: 5
  },
  {
    label: '10 MB',
    value: 10
  },
  {
    label: '20 MB',
    value: 20
  },
  {
    label: '50 MB',
    value: 50
  },
  {
    label: '100 MB',
    value: 100
  }
]
