import React, { useState, useContext, useEffect } from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import GlobalStore from '../../utils/store'
import { PageProvider, UserContext, emptyFn, fetchApi } from '../../utils/helpers'
import { PAGES, MAIN_TABS, LANGUAGE_OPTIONS, LANGUAGES } from '../../constants'

const WithPage = (props) => {
  const { user } = useContext(UserContext)
  const { children } = props
  const [page, setPage] = useState(PAGES.MAIN)
  const [pageTab, setPageTab] = useState(MAIN_TABS.SAMPLES)
  const [infoQueue, setInfoQueue] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [taskCreated, setTaskCreated] = useState(false)
  const [actionBarState, setActionBarState] = useState({
    showBar: false,
    onCancel: emptyFn,
    onAction: emptyFn,
    onOptionalAction: null,
    cancelLabel: 'Cancel',
    actionLabel: 'Start',
    optionalLabel: null,
    infoText: null,
    disableAction: false
  })
  const [filtersState, setFiltersState] = useState({
    showFilters: false,
    appliedFilters: {}
  })

  useEffect(() => {
    fetchLanguage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  const setActionBarProperties = (properties) => {
    setActionBarState({ ...actionBarState, ...properties })
  }

  const setFiltersProperties = (properties) => {
    setFiltersState({ ...filtersState, ...properties })
  }

  const closeInfoQueue = () => setInfoQueue(false)

  const handleErrorMsg = (error) => setErrorMsg(error)

  const fetchLanguage = async () => {
    const languages = get(GlobalStore.getLocal(), LANGUAGE_OPTIONS, []) || []

    if (user && !languages?.length) {
      const languagesRes = await fetchApi(LANGUAGES)
      if (!languagesRes?.error) {
        const languagesOptions = languagesRes?.map(el => ({
          value: el?.code,
          label: `${el?.code} (${el?.label})`
        }))
        GlobalStore.setData(languagesOptions, LANGUAGE_OPTIONS)
      }
    }
  }

  return (
    <>
      <PageProvider
        value={{
          page,
          setPage,
          pageTab,
          setPageTab,
          setInfoQueue,
          actionBarState,
          filtersState,
          taskCreated,
          setTaskCreated,
          setActionBarProperties,
          setFiltersProperties,
          infoQueue,
          closeInfoQueue,
          errorMsg,
          handleErrorMsg
        }}
      >
        {children}
      </PageProvider>
    </>
  )
}

WithPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WithPage