/* eslint-disable react/prop-types, max-len */
import React from 'react'
import palette from '../gatsby-theme-material-ui-top-layout/palette'

export const Logo = ({ width, height, fill, color }) => (
  <svg
    width={width || '64'}
    height={height || '64'}
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.6788 24.4354L17.3677 24.8454L19.9956 34.8912L20.4243 37.3327H20.5361L21.058 34.8912L25.0837 22.0684H28.0285L32.0729 34.8912L32.6507 37.6123H32.7625L33.303 35.721L36.0638 27.0471H39.383L34.2722 42.2345H31.3088L27.3948 29.9521L26.5561 26.5601L26.4443 26.5787L25.6615 29.9521L21.8221 42.2345H18.8587L13.7706 24.6963L12 24.4354V22.0684H19.6788V24.4354Z'
      fill={fill || color || palette.common.white}
    />
    <rect x='35.2341' y='22.0684' width='9.95745' height='3.31915' fill={fill || color || palette.common.white} />
    <rect x='47.6809' y='22.0684' width='3.31915' height='3.31915' fill={fill || color || palette.common.white} />
  </svg>
)

export const BoxLogo = ({ width, height, fill, color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 48 48'
    width={width || '48'}
    height={height || '48'}
  >
    <defs/>
    <rect width={width || '48'} height={height || '48'} fill={fill || color || palette.common.black} rx='4'/>
    <g fill={palette.common.white}>
      <path d='M14.7591 18.327l-1.7333.3075 1.9709 7.5344L15.3182 28h.0839l.3914-1.8311 3.0193-9.6171h2.2086l3.0333 9.6171.4333 2.0408h.0839l.4054-1.4185 2.0706-6.5054h2.4893l-3.8331 11.3905h-2.2225l-2.9355-9.2117-.629-2.5441-.0839.014-.5871 2.5301-2.8795 9.2117H14.144l-3.8161-13.1536L9 18.327v-1.7752h5.7591v1.7752z'/>
      <path d='M26.4255 16.5518h7.46809v2.48936H26.4255zM35.7606 16.5518h2.48936v2.48936H35.7606z'/>
    </g>
  </svg>
)

export const CrawlersNoData = () => (
  <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='4.38889' y='12.3889' width='71.2222' height='55.2222' rx='1.61111' fill='white' stroke='#D7DEE4' strokeWidth='0.777778'/>
    <rect x='8' y='21' width='64' height='8' rx='2' fill='#F7F7F7'/>
    <rect x='61' y='23' width='8' height='4' rx='2' fill='#D7DEE4'/>
    <rect x='54' y='23' width='4' height='4' rx='2' fill='#D7DEE4'/>
    <rect x='40' y='23' width='4' height='4' rx='2' fill='#58646F'/>
    <rect x='11' y='23' width='20' height='4' rx='2' fill='#D7DEE4'/>
    <rect x='8' y='31' width='64' height='8' rx='2' fill='#F7F7F7'/>
    <rect x='40' y='33' width='4' height='4' rx='2' fill='#58646F'/>
    <rect x='11' y='33' width='20' height='4' rx='2' fill='#D7DEE4'/>
    <rect x='8' y='41' width='64' height='8' rx='2' fill='#F7F7F7'/>
    <rect x='40' y='43' width='4' height='4' rx='2' fill='#58646F'/>
    <rect x='11' y='43' width='20' height='4' rx='2' fill='#D7DEE4'/>
    <rect x='8' y='51' width='64' height='8' rx='2' fill='#F7F7F7'/>
    <rect x='61' y='53' width='8' height='4' rx='2' fill='#D7DEE4'/>
    <rect x='54' y='53' width='4' height='4' rx='2' fill='#D7DEE4'/>
    <rect x='40' y='53' width='4' height='4' rx='2' fill='#58646F'/>
    <rect x='11' y='53' width='20' height='4' rx='2' fill='#D7DEE4'/>
    <mask id='path-18-inside-1_122_18892' fill='white'>
      <path fillRule='evenodd' clipRule='evenodd' d='M60.3887 54C67.8445 54 73.8887 47.9558 73.8887 40.5C73.8887 33.0442 67.8445 27 60.3887 27C52.9328 27 46.8887 33.0442 46.8887 40.5C46.8887 47.9558 52.9328 54 60.3887 54ZM60.3887 51.0811C66.2325 51.0811 70.9698 46.3438 70.9698 40.5C70.9698 34.6563 66.2325 29.9189 60.3887 29.9189C54.5449 29.9189 49.8076 34.6563 49.8076 40.5C49.8076 46.3438 54.5449 51.0811 60.3887 51.0811Z'/>
    </mask>
    <path fillRule='evenodd' clipRule='evenodd' d='M60.3887 54C67.8445 54 73.8887 47.9558 73.8887 40.5C73.8887 33.0442 67.8445 27 60.3887 27C52.9328 27 46.8887 33.0442 46.8887 40.5C46.8887 47.9558 52.9328 54 60.3887 54ZM60.3887 51.0811C66.2325 51.0811 70.9698 46.3438 70.9698 40.5C70.9698 34.6563 66.2325 29.9189 60.3887 29.9189C54.5449 29.9189 49.8076 34.6563 49.8076 40.5C49.8076 46.3438 54.5449 51.0811 60.3887 51.0811Z' fill='#27313A'/>
    <path d='M72.8887 40.5C72.8887 47.4036 67.2922 53 60.3887 53V55C68.3968 55 74.8887 48.5081 74.8887 40.5H72.8887ZM60.3887 28C67.2922 28 72.8887 33.5964 72.8887 40.5H74.8887C74.8887 32.4919 68.3968 26 60.3887 26V28ZM47.8887 40.5C47.8887 33.5964 53.4851 28 60.3887 28V26C52.3805 26 45.8887 32.4919 45.8887 40.5H47.8887ZM60.3887 53C53.4851 53 47.8887 47.4036 47.8887 40.5H45.8887C45.8887 48.5081 52.3805 55 60.3887 55V53ZM69.9698 40.5C69.9698 45.7915 65.6802 50.0811 60.3887 50.0811V52.0811C66.7848 52.0811 71.9698 46.8961 71.9698 40.5H69.9698ZM60.3887 30.9189C65.6802 30.9189 69.9698 35.2085 69.9698 40.5H71.9698C71.9698 34.104 66.7848 28.9189 60.3887 28.9189V30.9189ZM50.8076 40.5C50.8076 35.2085 55.0972 30.9189 60.3887 30.9189V28.9189C53.9926 28.9189 48.8076 34.104 48.8076 40.5H50.8076ZM60.3887 50.0811C55.0972 50.0811 50.8076 45.7915 50.8076 40.5H48.8076C48.8076 46.8961 53.9926 52.0811 60.3887 52.0811V50.0811Z' fill='#27313A' mask='url(#path-18-inside-1_122_18892)'/>
    <path d='M69.8887 50L77.8887 58' stroke='#27313A' strokeWidth='3' strokeLinecap='round'/>
    <mask id='mask0_122_18892' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='49' y='30' width='22' height='21'>
      <circle cx='60.3887' cy='40.5' r='10.5' fill='white'/>
    </mask>
    <g mask='url(#mask0_122_18892)'>
      <rect x='61' y='32' width='9' height='6' rx='3' fill='#D7DEE4'/>
      <rect x='61' y='42' width='9' height='6' rx='3' fill='#D7DEE4'/>
      <rect x='53' y='32' width='6' height='6' rx='3' fill='#D7DEE4'/>
      <rect x='53' y='42' width='6' height='6' rx='3' fill='#D7DEE4'/>
    </g>
  </svg>
)

export const PredictorsNoData = () => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 80 80' width='80' height='80'>
    <rect width='71.2222' height='55.2222' x='4.38889' y='12.3889' fill='#fff' stroke='#D7DEE4' strokeWidth='.777778' rx='1.61111'/>
    <rect width='64' height='12' x='8' y='18' fill='#F7F7F7' rx='2'/>
    <rect width='8' height='8' x='10' y='20' fill='#58646F' rx='2'/>
    <rect width='14' height='3' x='20' y='20' fill='#D7DEE4' rx='1.5'/>
    <rect width='7' height='4' x='61' y='22' fill='#D7DEE4' rx='2'/>
    <rect width='4' height='4' x='54' y='22' fill='#D7DEE4' rx='2'/>
    <rect width='24.8889' height='3' x='20' y='25' fill='#D7DEE4' rx='1.5'/>
    <rect width='64' height='12' x='8' y='34' fill='#F7F7F7' rx='2'/>
    <rect width='64' height='12' x='8' y='50' fill='#F7F7F7' rx='2'/>
    <rect width='8' height='8' x='10' y='36' fill='#58646F' rx='2'/>
    <rect width='8' height='8' x='10' y='52' fill='#58646F' rx='2'/>
    <rect width='14' height='3' x='20' y='36' fill='#D7DEE4' rx='1.5'/>
    <rect width='14' height='3' x='20' y='52' fill='#D7DEE4' rx='1.5'/>
    <rect width='24.8889' height='3' x='20' y='41' fill='#D7DEE4' rx='1.5'/>
    <rect width='24.8889' height='3' x='20' y='57' fill='#D7DEE4' rx='1.5'/>
    <rect width='7' height='4' x='61' y='54' fill='#D7DEE4' rx='2'/>
    <rect width='4' height='4' x='54' y='54' fill='#D7DEE4' rx='2'/>
    <mask id='a' fill='#fff'>
      <path fillRule='evenodd' d='M60.3887 54c7.4558 0 13.5-6.0442 13.5-13.5S67.8445 27 60.3887 27c-7.4559 0-13.5 6.0442-13.5 13.5s6.0441 13.5 13.5 13.5Zm0-2.9189c5.8438 0 10.5811-4.7373 10.5811-10.5811 0-5.8437-4.7373-10.5811-10.5811-10.5811-5.8438 0-10.5811 4.7374-10.5811 10.5811 0 5.8438 4.7373 10.5811 10.5811 10.5811Z' clipRule='evenodd'/>
    </mask>
    <path fill='#27313A' fillRule='evenodd' d='M60.3887 54c7.4558 0 13.5-6.0442 13.5-13.5S67.8445 27 60.3887 27c-7.4559 0-13.5 6.0442-13.5 13.5s6.0441 13.5 13.5 13.5Zm0-2.9189c5.8438 0 10.5811-4.7373 10.5811-10.5811 0-5.8437-4.7373-10.5811-10.5811-10.5811-5.8438 0-10.5811 4.7374-10.5811 10.5811 0 5.8438 4.7373 10.5811 10.5811 10.5811Z' clipRule='evenodd'/>
    <path fill='#27313A' d='M72.8887 40.5c0 6.9036-5.5965 12.5-12.5 12.5v2c8.0081 0 14.5-6.4919 14.5-14.5h-2Zm-12.5-12.5c6.9035 0 12.5 5.5964 12.5 12.5h2c0-8.0081-6.4919-14.5-14.5-14.5v2Zm-12.5 12.5c0-6.9036 5.5964-12.5 12.5-12.5v-2c-8.0082 0-14.5 6.4919-14.5 14.5h2Zm12.5 12.5c-6.9036 0-12.5-5.5964-12.5-12.5h-2c0 8.0081 6.4918 14.5 14.5 14.5v-2Zm9.5811-12.5c0 5.2915-4.2896 9.5811-9.5811 9.5811v2c6.3961 0 11.5811-5.185 11.5811-11.5811h-2Zm-9.5811-9.5811c5.2915 0 9.5811 4.2896 9.5811 9.5811h2c0-6.396-5.185-11.5811-11.5811-11.5811v2ZM50.8076 40.5c0-5.2915 4.2896-9.5811 9.5811-9.5811v-2c-6.3961 0-11.5811 5.1851-11.5811 11.5811h2Zm9.5811 9.5811c-5.2915 0-9.5811-4.2896-9.5811-9.5811h-2c0 6.3961 5.185 11.5811 11.5811 11.5811v-2Z' mask='url(#a)'/>
    <path stroke='#27313A' strokeLinecap='round' strokeWidth='3' d='m69.8887 50 8 8'/>
    <mask id='b' width='22' height='21' x='49' y='30' maskUnits='userSpaceOnUse' style={{ maskType: 'alpha' }}>
      <circle cx='60.3887' cy='40.5' r='10.5' fill='#fff'/>
    </mask>
    <g fill='#D7DEE4' mask='url(#b)'>
      <rect width='8' height='6' x='61' y='37' rx='3'/><
      rect width='6' height='6' x='53' y='37' rx='3'/>
    </g>
  </svg>
)

export const TextAreaSvg = (props) => (
  <svg width='9' height='10' viewBox='0 0 9 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M8.61401 8.15157C8.65763 8.75913 8.15253 9.26423 7.54496 9.22061L0.983886 8.74955C0.129333 8.68819 -0.257424 7.65082 0.348392 7.04501L6.43841 0.954993C7.04422 0.349177 8.08159 0.735935 8.14294 1.59049L8.61401 8.15157Z' fill='#D7DEE4'/>
  </svg>
)