import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { FormControl, FormControlLabel, FormGroup, Checkbox } from '@mui/material'

import { emptyFn } from '../../utils/helpers'

const defaultFormatter = (entry) => entry.label || entry.name || 'N/A'

const GenericCheckboxGroup = (props) => {
  const {
    onChange,
    items,
    name,
    selected,
    formatter,
    disabledDefault,
    formControlLabelSx,
    ...otherProps
  } = props

  const handleChange = (e) => {
    const { value } = e?.target || e
    const valueIdx = selected.findIndex(el => el === value)
    const newValue = valueIdx > -1
      ? [
        ...selected.slice(0, valueIdx),
        ...selected.slice(valueIdx + 1, items.length)
      ]
      : [...selected, value]

    onChange(name, newValue)
  }

  return (
    <FormControl component='fieldset' sx={{ width: '100%' }}>
      <FormGroup>
        {get(props, 'items', []).map((el, idx) => {
          const val = get(el, 'value', null)

          return (
            <FormControlLabel
              key={`checkbox-item-${idx}`}
              componentsProps={{
                typography: {
                  variant: 'subtitle1',
                  sx: {
                    width: '100%',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textAlign: 'left'
                  }
                }}}
              value={val}
              control={
                <Checkbox
                  {...otherProps}
                  disabled={disabledDefault?.indexOf(val) > -1}
                  color='secondary'
                  onChange={handleChange}
                  checked={selected?.indexOf(val) > -1}
                />
              }
              label={formatter(el)}
              {...(formControlLabelSx ? { sx: formControlLabelSx } : {})}
              {...otherProps}
            />
          )
        })}
      </FormGroup>
    </FormControl>
  )
}

GenericCheckboxGroup.propTypes = {
  selected: PropTypes.array,
  onChange: PropTypes.func,
  formatter: PropTypes.func,
  items: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  formControlLabelSx: PropTypes.object,
  disabled: PropTypes.bool,
  disabledDefault: PropTypes.array
}

GenericCheckboxGroup.defaultProps = {
  selected: [],
  disabledDefault: [],
  onChange: emptyFn,
  formatter: defaultFormatter
}

export default GenericCheckboxGroup
