import React from 'react'
import PropTypes from 'prop-types'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Typography, InputLabel, Tooltip } from '@mui/material'

import GenericSelect from './GenericSelect'
import { emptyFn } from '../../utils/helpers'

const RowDropdownWithLabel = (props) => {
  const {
    label,
    value,
    onChange,
    options,
    name,
    styles,
    selectLabel,
    required,
    infoText,
    multiple,
    disabled
  } = props

  return (
    <Box
      component='div'
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...styles
      }}
    >
      {(required || infoText?.length) ? (
         <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
           <InputLabel
             required={required}
             htmlFor='urls'
           >{label}</InputLabel>
           {infoText && infoText?.length && (
             <Tooltip
               title={infoText}
               placement='bottom-start'>
               <InfoIcon sx={{color: 'grey.300', ml: 0.5, cursor: 'pointer'}}/>
             </Tooltip>
           )}
         </Box>
      )
        : (<Typography component='p' variant='subtitle2'>{label}</Typography>)}
      <GenericSelect
        name={name}
        disabled={disabled}
        options={options}
        onChange={onChange}
        value={value}
        label={selectLabel}
        multiple={multiple}
      />
    </Box>
  )
}

RowDropdownWithLabel.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  selectLabel: PropTypes.string,
  onChange: PropTypes.func,
  styles: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  options: PropTypes.array,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  infoText: PropTypes.string,
  disabled: PropTypes.bool
}

RowDropdownWithLabel.defaultProps = {
  onChange: emptyFn,
  styles: {},
  multiple: false
}

export default RowDropdownWithLabel
