import React from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import {
  Drawer,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button
} from '@mui/material'

const GenericDrawer = (props) => {
  const { open,
    onClose,
    title,
    children,
    onCancel,
    onSubmit,
    cancelLabel,
    submitLabel,
  } = props

  return (
    <Drawer
      open={open}
      anchor='right'
      onClose={onClose}
      sx={theme => ({
        '& .MuiDrawer-paper': {
          width: 417,
          p: theme.spacing(6, 5, 0, 5)
        }
      })}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 21,
          top: 21
        }}
      >
        <CloseIcon fontSize='medium' sx={{ color: 'text.primary' }} />
      </IconButton>
      <DialogTitle sx={{ mb: 2, p: 0 }}>
        <Typography component='p' variant='h1' sx={{ textAlign: 'left' }}>{title}</Typography>
      </DialogTitle>
      <DialogContent sx={{ mr: -5, ml: -3, pr: 5 }}>
        {children}
      </DialogContent>
      <DialogActions
        sx={theme => ({
          borderTop: `1px solid ${theme.palette.grey[400]}`,
          p: theme.spacing(2, 5),
          m: theme.spacing(0, -5),
          display: 'flex',
          justifyContent: 'space-between'
        })}
      >
        {onCancel && (<Button
          variant={'outlined'}
          onClick={onCancel}
        >
          {cancelLabel}
        </Button>)}
        {onSubmit && (<Button
          variant='contained'
          onClick={onSubmit}
        >
          {submitLabel}
        </Button>)}
      </DialogActions>
    </Drawer>
  )
}

GenericDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string
}

GenericDrawer.defaultProps = {
  cancelLabel: 'Cancel',
  submitLabel: 'Submit'
}

export default GenericDrawer
