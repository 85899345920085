import React from 'react'
import { Box, Skeleton } from '@mui/material'

const LoadingCrawlerSampleRow = () => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 32,
      mb: 1
    }}>
    <Skeleton
      animation='wave'
      variant='text'
      height={20}
      width='45%'
      sx={theme => ({
        borderRadius: 0,
        background: `linear-gradient(90.44deg, ${theme.palette.grey[400]}
         -86.35%, ${theme.palette.common.white} 167.55%)`
      })}
    />
    <Skeleton
      animation='wave'
      variant='text'
      height={20}
      width='13%'
      sx={theme => ({
        borderRadius: 0,
        background: `linear-gradient(90.44deg, ${theme.palette.grey[400]}
         -86.35%, ${theme.palette.common.white} 167.55%)`
      })}
    />
  </Box>
)

export default LoadingCrawlerSampleRow
