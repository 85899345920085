import React from 'react'
import fetch from 'isomorphic-unfetch'
import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'
import get from 'lodash/get'
import { API_URL } from 'gatsby-env-variables'

import { GET, OPTIONS, HEAD } from '../constants'

const MAX_BRIGHTNESS = 0.51

export const emptyFn = () => {}

const hexToRgb = (hex) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  const adjustedHex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(adjustedHex) || []
  const [, r, g, b] = result.map((val) => val && parseInt(val, 16))

  return result ? { r, g, b } : null
}

const computeLuminance = ({ r, g, b }) => {
  const a = [r, g, b].map((val) => {
    val /= 255;
    return val <= 0.03928 ? val / 12.92 : Math.pow((val + 0.055) / 1.055, 2.4)
  })

  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

const getLuminance = (hex) => {
  const rgb = hexToRgb(hex)
  return computeLuminance(rgb)
}

export const isLightColor = (hex) => {
  const brightness = getLuminance(hex)
  return brightness >= MAX_BRIGHTNESS
}

export const getNeighbours = (list, startIndex, endIndex) => {
  const isEndBigger = endIndex >= startIndex
  const before = isEndBigger ? list[endIndex] : list[endIndex - 1]
  const after = isEndBigger ? list[endIndex + 1] : list[endIndex]

  return [before, after]
}

export const reorder = (list = [], startIndex, endIndex) => {
  const step = 10000
  const [before, after] = getNeighbours(list, startIndex, endIndex)
  const left = get(before, 'order', 0)
  const right = get(after, 'order', step)

  return after ? (left + right) / 2 : left + right
}

export const download = (filename, text) => {
  if (window && document) {
    let element = document.createElement('a')
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
    element.setAttribute('download', filename)

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }
}

export const downloadFile = (filename, blob) => {
  if (window && document) {
    const url = window.URL.createObjectURL(blob)
    const element = document.createElement('a')
    element.setAttribute('href', url)
    element.setAttribute('download', filename)

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
    window.URL.revokeObjectURL(url)
  }
}

const responseParser = async (res) => {
  let parser
  const contentType = res.headers.get('Content-Type')
  if (contentType && (contentType.match(/application\/(ld\+)?json/) || {}).input) {
    parser = await res.json()
  } else if (contentType && (contentType.match(/application\/(ld\+)?octet-stream/) || {}).input) {
    parser = await res.blob()
  } else {
    parser = await res.text()
  }

  // resolver parser and return data as well as original response
  return {
    data: parser,
    res
  }
}

const resolveStatus = ({ data, res }) => {
  if (res.status >= 200 && res.status < 300) {
    return data
  }
  let dataErrors = []

  if (!data?.detail && typeof data !== 'string') {
    forEach((data || {}), (value, key) => {
      if (isArray(value)) {
        dataErrors = [...dataErrors, `${key}: ${(isArray(value) ? value : [])?.map(el => el).join(', ')}`]
      } else {
        forEach((value || {}), (val) => {
          dataErrors = [...dataErrors, `${key}: ${(isArray(val) ? val : [])?.map(el => el).join(', ')}`]
        })
      }
    })
  }

  const error = new Error(dataErrors?.join(', ') || res.statusText)
  error.res = data
  error.status = res?.status
  throw error
}

export const fetchApi = async (endpoint, method = GET, body = {}, headers = {}, signal) => {
  const token = window?.sessionStorage?.getItem('token')
  const url = `${API_URL}${endpoint}`
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token}`,
      ...(headers || {})
    },
    signal
  }
  if (method === GET || method === OPTIONS || method === HEAD) {
    options.body = undefined
  } else {
    options.body = JSON.stringify(body)
  }

  try {
    const response = await fetch(url, options)
    const parseRes = await responseParser(response)
    const parseResStatus = await resolveStatus(parseRes)

    return parseResStatus?.data || parseResStatus
  } catch (e) {
    console.log(`Error on fetch ${endpoint}: `, e?.message || e)

    if (e?.status === 401 || e?.status === 403) {
      if (window) {
        window?.sessionStorage.removeItem('user')
        window?.sessionStorage.removeItem('token')
        window.location.reload()
      }
    }

    return {
      error: e?.message || e
    }
  }
}

export const parseObjToQueryParams = (params = {}) => {
  const paramsArray = []
  forEach(params, (value, key) => {
    if (value) {
      paramsArray.push(`${key}=${isArray(value) && value?.length > 0 ? value.join(',') : value}`)
    }
  })

  return paramsArray?.length ? encodeURI(`?${paramsArray.join('&')}`) : ''
}

export const splitAllowedBlockedString = (str) => {
  const result = []
  str.split(',').forEach(el => {
    el.split(/[ ]+/).forEach(item => {
      result.push(item)
    })
  })

  return result.filter(el => !!el)
}

export const UserContext = React.createContext({})
export const UserProvider = UserContext.Provider

export const PageContext = React.createContext({})
export const PageProvider = PageContext.Provider

export const PredictorBuildContext = React.createContext({})
export const PredictorBuildProvider = PredictorBuildContext.Provider

export const PredictorOptionsContext = React.createContext({})
export const PredictorOptionsProvider = PredictorOptionsContext.Provider

export const SampleCrawlerContext = React.createContext({})
export const SampleCrawlerProvider = SampleCrawlerContext.Provider
