import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import usePrevious from '../../hooks/usePrevious'
import LoadingCrawlerSampleRow from '../predictors/LoadingCrawlerSampleRow'
import {
  fetchApi,
  parseObjToQueryParams,
  PageContext,
  SampleCrawlerContext
} from '../../utils/helpers'
import { SAMPLES } from '../../constants'
import { Summary, DetailsRow } from './accordionComponents'

const AccordionCheckbox = (props) => {
  const {
    name,
    value,
    label,
    count,
    formatter,
    sxContainer,
    sampleParams: { page, ...restOfParams },
    selectedDomain
  } = props
  const { handleErrorMsg } = useContext(PageContext)
  const {
    selected,
    unselected,
    expanded,
    selectAll,
    onChange,
    handleUserInteractions,
    onItemsChange
    // handleNoOfSelected
  } = useContext(SampleCrawlerContext)
  const [accordionCheckbox, setAccordionCheckbox] = useState((!unselected?.size && !selected?.size)
    ? selectAll
    : selectedDomain)
  // const [accordionIndeterminate, setAccordionIndeterminate] = useState(false)
 // const [selectAllSamples, setSelectAllSamples] = useState(false)
  const [urls, setUrls] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const isExpanded = expanded === value
  const prevSelectAll = usePrevious(selectAll)

  useEffect(() => {
    if (isExpanded) {
      setIsLoading(true)
      fetchUrls()
    }
    // handle Accordion checkbox when the selectAll changes
    if (prevSelectAll !== selectAll) {
      const checkedVal = (!unselected?.size && !selected?.size)
        ? selectAll
        : selectedDomain
      setAccordionCheckbox(checkedVal)
      // setSelectAllSamples(checkedVal)
      // setAccordionIndeterminate(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded, selectAll])

  const fetchUrls = async () => {
    // prevent re-fetch if the URLS are defined
    if (urls?.length) {
      setIsLoading(false)
      return
    }

    const params = {
      ...(restOfParams || {}),
      mode: 'brief',
      domain: value
    }

    const res = await fetchApi(`${SAMPLES}${parseObjToQueryParams(params)}`)
    if (!res?.error) {
      const urlsRes = res?.results?.map(el => ({
        label: el?.url,
        value: el?.id
      }))
      setUrls(urlsRes)

      if (selectAll && selectedDomain) { //&& !accordionIndeterminate
        setAccordionCheckbox(true)
        // setSelectAllSamples(true)
      }
      setIsLoading(false)
    } else {
      handleErrorMsg(res?.error)
    }
  }

  const handleChange = () => {
    onChange({ name, value })
  }

  const handleCheckboxAccordion = (e) => {
    const { checked } = e?.target || e
    // const checkedVal = accordionIndeterminate ? false : checked
    // if (accordionIndeterminate || !checked) {
    //   handleNoOfSelected(accordionIndeterminate ? -selected?.size : -count)
    // } else {
    //   handleNoOfSelected(count)
    // }
    setAccordionCheckbox(checked)
    // setSelectAllSamples(checkedVal)
    // setAccordionIndeterminate(false)
    // Register domain user interactions
    handleUserInteractions({
      mode: 'domain',
      checked,
      query: parseObjToQueryParams({page, ...restOfParams}),
      item: value
    })

    onItemsChange(value)
  }

  // const handleChangeCheckboxes = (e) => {
  //   const { value: val, checked } = e?.target || e
  //   const newSelectedSize = (selected?.has(val) ? -1 : 1) + urls.reduce((sum, url) => {
  //     if (selected?.has(`${url?.value}`)) {
  //       sum = sum + 1
  //     }
  //     return sum
  //   }, 0)
  //   const newUnselectedSize = (unselected?.has(val) ? -1 : 1) + urls.reduce((sum, url) => {
  //     if (unselected?.has(`${url?.value}`)) {
  //       sum = sum + 1
  //     }
  //     return sum
  //   }, 0)
  //
  //   const indeterminate = selectAll
  //     ? count === newUnselectedSize ? false : newUnselectedSize > 0
  //     : count === newSelectedSize ? false : newSelectedSize > 0
  //
  //   // if (indeterminate)   {
  //     // handleNoOfSelected(newValue?.length > selected?.size
  //     // ? newValue?.length - selected?.size
  //     // : -(selected?.size - newValue?.length))
  //   // }
  //
  //   onItemsChange(val)
  //   setAccordionIndeterminate(indeterminate)
  //   if (!indeterminate) {
  //     setAccordionCheckbox(selectAll ? newUnselectedSize === 0 : newSelectedSize === count)
  //   }
  //
  //   // Register domain user interactions
  //   handleUserInteractions({
  //     mode: 'sample',
  //     checked,
  //     query: parseObjToQueryParams({ mode: 'brief', domain: value, ...restOfParams }),
  //     item: val
  //   })
  // }

  return (
    <Box
      sx={{
        width: '100%',
        ...(isExpanded
          ? {
            borderRadius: 0,
            border: '1px solid',
            borderColor: 'grey.400'
          }
          : {}),
        ...(sxContainer || {})
      }}
    >
      <Summary
        isExpanded={isExpanded}
        // indeterminate={accordionIndeterminate}
        checked={accordionCheckbox}
        itemsNo={count}
        label={label}
        onCheckbox={handleCheckboxAccordion}
        onExpand={handleChange}
      />
      {isLoading && (<Box sx={{ mr: 2, ml: 2 }}><LoadingCrawlerSampleRow /></Box>)}
      {
        isExpanded && urls.map((el, idx) => (
            <DetailsRow
              key={`accordion-item-${idx}`}
              formatter={formatter}
              label={el?.label}
              // value={el?.value}
              // checked={selectAll
              //   ? !unselected?.has(`${el.value}` || selectAllSamples)
              //   : selected?.has(`${el?.value}` || selectAllSamples)
              // }
              // onChange={handleChangeCheckboxes}
            />
          ))
      }
    </Box>
  )
}

AccordionCheckbox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number,
  formatter: PropTypes.func,
  sxContainer: PropTypes.object,
  sampleParams: PropTypes.object,
  selectedDomain: PropTypes.bool,
}

export default AccordionCheckbox