import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, Typography, Button } from '@mui/material'

import GlobalStore from '../../../utils/store'
import { GenericAvatar, MenuIcon } from '../../common'
import { UserContext, PageContext, fetchApi, downloadFile } from '../../../utils/helpers'
import { GET, PAGES, POST, PREDICTOR_COPY, PREDICTOR_DOWNLOAD } from '../../../constants'

const boxStyle = { display: 'flex', alignItems: 'center' }
const options = [
  { label: 'Duplicate', value: 'duplicate' },
  { label: 'Download', value: 'download' },
  { label: 'Merge with...', value: 'mergeWith' }
]

const ViewHeader = (props) => {
  const { setPage, handleErrorMsg } = useContext(PageContext)
  const { user } = useContext(UserContext)
  const {
    languages,
    name,
    date,
    user_id,
    user_initials,
    image,
    size,
    handleDelete,
    handleDuplicate,
    id,
  } = props
  const displayDate = dayjs(date)
  const userOptions = user?.id === user_id
    ? [...options, { label: 'Delete', value: 'delete' }]
    : options

  const actions = {
    duplicate: async () => {
      const result = await fetchApi(PREDICTOR_COPY(id), POST,)
      if (result?.error) {
        handleErrorMsg(result?.error)
      } else {
        handleDuplicate(result?.id)
      }
    },
    download: async () => {
      const blob = await fetchApi(PREDICTOR_DOWNLOAD(id), GET, null, {
        'Content-Type': 'application/octet-stream',
        'Content-Length': size
      })
      downloadFile(`${name}.wp`, blob)
    },
    mergeWith: () => {
      GlobalStore.setData({ id, languages, name, view: true }, PAGES.PREDICTOR_MERGE)
      setPage(PAGES.PREDICTOR_MERGE)
    },
    ...(user?.id === user_id ? { delete: () => handleDelete(id) } : {})
  }

  const handleEdit = () => {
    GlobalStore.setData({ id, view: true }, PAGES.PREDICTOR_EDIT)
    setPage(PAGES.PREDICTOR_EDIT)
  }
  const handleTest = () => {
    GlobalStore.setData({ id, name, view: true }, PAGES.PREDICTOR_TEST)
    setPage(PAGES.PREDICTOR_TEST)
  }

  return (
    <Box sx={{
      ...boxStyle,
      justifyContent: 'space-between',
      borderBottom: '1px solid',
      borderColor: 'grey.400',
      width: '100%',
      py: 3
    }}>
      <Box sx={boxStyle}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 64,
            minHeight: 64,
            borderRadius: '2px',
            backgroundColor: 'grey.200',
            mr: 2
          }}
        >
          <Typography
            component='p'
            variant='body2'
            sx={{ color: 'common.white' }}
          >
            {languages?.length > 0 ? languages[0]?.split(' (')[0] : 'No-lang'}
          </Typography>
        </Box>
        <Typography
          component='p'
          variant='h1'
        >
          {name}
        </Typography>
      </Box>
      <Box sx={boxStyle}>
        <Typography
          component='p'
          variant='subtitle1'
          sx={{ color: 'grey.300', mr: 2 }}
        >
          {`Created ${displayDate.isValid() ? displayDate.locale('en').format('MMM D') : '-'}`}
        </Typography>
        <GenericAvatar
          initials={user_initials || ''}
          src={image || ''}
          id={user_id}
        />
        <Button
          variant='contained'
          sx={{ minWidth: 52, mr: 1.5 }}
          onClick={handleTest}
        >
          Test
        </Button>
        <Button
          variant='outlined'
          sx={{ backgroundColor: 'common.white', minWidth: 48, maxWidth: 48, mr: 2 }}
          onClick={handleEdit}
        >
          Edit
        </Button>
        <MenuIcon
          sx={{
            width: 32,
            height: 32,
            borderRadius: 0.5,
            '&:hover': {
              border: '1px solid',
              borderColor: 'grey.300',
              backgroundColor: 'transparent'
            }
          }}
          actions={actions}
          options={userOptions}
        >
          <MoreHorizIcon
            fontSize='small'
            sx={{ color: 'grey.300' }}/>
        </MenuIcon>
      </Box>
    </Box>
  )
}

ViewHeader.propTypes = {
  languages: PropTypes.array,
  name: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  username: PropTypes.string,
  id: PropTypes.number,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  user_id: PropTypes.number,
  user_initials: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  allowed: PropTypes.array,
  blocked: PropTypes.array,
  abbr_exp: PropTypes.array,
  handleDelete: PropTypes.func,
  handleDuplicate: PropTypes.func
}

export default ViewHeader