import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

const NoDataBox = (props) => {
  const { subtitle, text, Icon, noBorder } = props

  return (
    <Box
      component='div'
      sx={theme => ({
        width: '100%',
        height: 256,
        backgroundColor: theme.palette.background[100],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        ...(noBorder ? {} : {
          border: `1px solid ${theme.palette.grey[400]}`,
          borderRadius: '2px'
        })
      })}
    >
      {Icon && <Icon />}
      <Typography component='p' variant='subtitle2' sx={{ maxWidth: 364 }}>
        {subtitle}
      </Typography>
      {text && (
        <Typography component='p' variant='subtitle1' sx={{ maxWidth: 364 }}>
          {text}
        </Typography>
      )}
    </Box>
  )
}

NoDataBox.propTypes = {
  subtitle: PropTypes.string,
  text: PropTypes.string,
  Icon: PropTypes.elementType,
  noBorder: PropTypes.bool
}

NoDataBox.defaultProps = {
  subtitle: 'No data'
}

export default NoDataBox

