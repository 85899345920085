import React, { useState, useContext } from 'react'
import { Grid, Typography, Tabs, Tab, Box } from '@mui/material'

import { PageContext } from '../../utils/helpers'
import { MAIN_TABS } from '../../constants'
import { CrawlersData } from '../crawlers'
import { PredictorsData } from '../predictors'

const tabOptions = [
  {
    value: MAIN_TABS.SAMPLES,
    label: 'Samples'
  },
  {
    value: MAIN_TABS.PREDICTORS,
    label: 'Predictors'
  }
]

const tabComp = {
  samples: CrawlersData,
  predictors: PredictorsData
}

const MainPage = () => {
  const { pageTab, setPageTab, setFiltersProperties } = useContext(PageContext)
  const [state, setState] = useState({
    tab: pageTab
  })
  const Comp = tabComp[state?.tab] || null

  const handleChange = (e, newValue) => {
    const {name, value} = e?.target || e

    if (newValue) {
      setState({
        ...state,
        tab: newValue
      })
      setPageTab(newValue)
      if (state?.tab !== newValue) {
        setFiltersProperties({ appliedFilters: {} })
      }
    } else {
      setState({
        ...state,
        [name]: value
      })
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography
          component='h1'
          variant='h1'
          sx={{textAlign: 'left', mb: 2}}
        >
          Your Data
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{borderBottom: 1, borderColor: 'grey.400'}}>
          <Tabs
            name='tabs'
            sx={{ minHeight: 'unset' }}
            value={state?.tab}
            onChange={handleChange}
          >
            {tabOptions.map(el => (<Tab key={`tab-${el?.value}`} {...el} disableFocusRipple/>))}
          </Tabs>
        </Box>
      </Grid>
      {Comp ? <Comp /> : null}
    </Grid>
  )
}

export default MainPage
