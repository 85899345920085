import React, { useContext, useState, useCallback, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import AbortController from 'abort-controller'
import { Grid, Box } from '@mui/material'

import LoadingCrawlerRow from './LoadingCrawlerRow'
import ViewTitle from './viewCrawler/ViewTitle'
import CrawlerRow from './CrawlerRow'
import { Banner, NoDataBox, SearchBar, PreviewDialog } from '../common'
import { PageContext, UserContext, fetchApi, parseObjToQueryParams } from '../../utils/helpers'
import { PAGES, GET, SAMPLES, CrawlersNoData } from '../../constants'
import { createCrawlerText } from '../../utils/crawlerHelpers'

const CrawlersData = () => {
  const { setPage, filtersState: { appliedFilters, samples } } = useContext(PageContext)
  const { user } = useContext(UserContext)
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [crawler, setCrawler] = useState(null)
  const [data, setData] = useState(null)
  const [samplesNo, setSamplesNo] = useState(0)
  const [search, setSearch] = useState('')
  const [controller, setController] = useState(null)

  useEffect(() => {
    setLoading(true)
    fetchSamples(1)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    search,
    appliedFilters?.users,
    appliedFilters?.langs,
    appliedFilters?.date,
    appliedFilters?.sizes
  ])

  const fetchSamples = async (page, params = {}) => {
    const loadMore = page > 1
    let signal = null
    if (!loadMore) {
      const ctrl = new AbortController()
      setController(ctrl)
      signal = ctrl.signal
    }
    if (samples) {
      return
    }

    const newParams = {
      page: page || 1,
      search,
      ...appliedFilters,
      ...params
    }
    const result = await fetchApi(`${SAMPLES}${parseObjToQueryParams(newParams)}`, GET, null, {}, signal)
    if (!result?.error) {
      setData(loadMore ? [...(data || []), ...(result?.results || [])] : result?.results)
      setHasMore(!!result?.next)
      setSamplesNo(result?.count)
    }
    setLoading(false)
  }

  const ViewTitleComp = useCallback(() => crawler && (
    <ViewTitle {...(crawler || {})} />
  ), [crawler])

  const ViewContentComp = useCallback(() => createCrawlerText(crawler?.content), [crawler])

  const handleStart = () => setPage(PAGES.CRAWL_CONFIGURE)

  const closeView = () => setCrawler(null)

  const onSearch = (value) => {
    if (controller) {
      controller.abort()
      setController(null)
    }
    setSearch(value)
  }

  return (
    <>
      <Grid item xs={12}>
        <Banner
          iconSrc='crawl.png'
          title='Start a Web Crawl'
          subtitle='Set up a web crawl to generate samples and start building predictors.'
          btnLabel='Start Crawl'
          onClick={handleStart}
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={theme => ({
            width: '100%',
            backgroundColor: theme.palette.common.white,
            border: loading || !data?.length ? 0: `1px solid ${theme.palette.background[100]}`,
            borderRadius: '2px'
          })}
        >
          <SearchBar onSearch={onSearch} resultsNo={samplesNo}/>
          {loading ? (<Box
            sx={theme => ({
              width: '100%',
              backgroundColor: theme.palette.common.white,
              padding: theme.spacing(2.5, 5.5, 1.5, 2)
            })}
          >
            {Array.from({length: 6}, (v, k) => k).map(k => (
              <LoadingCrawlerRow key={`loading-row-${k}`}/>
            ))}
          </Box>)
          : data?.length ? (
            <Grid container sx={{m: 0, px: 0, py: 1}}>
              <InfiniteScroll
                pageStart={1}
                style={{ width: '100%' }}
                loadMore={fetchSamples}
                hasMore={hasMore}
                loader={
                  <Box key={`loader-infinite-scroll`} sx={theme => ({width: '100%', padding: theme.spacing(0, 2)})}>
                    <LoadingCrawlerRow/>
                  </Box>
                }
              >
                {data.map(row => (
                  <Grid key={`crawler-row-container-${row?.id}`} id={row?.id} item xs={12}>
                    <CrawlerRow {...row} handleView={setCrawler}/>
                  </Grid>
                ))}
              </InfiniteScroll>
            </Grid>
          ) : (
            <NoDataBox
              noBorder
              Icon={CrawlersNoData}
              subtitle='No samples found'
            />
          )}
        </Box>
      </Grid>
      <PreviewDialog
        onClose={closeView}
        open={!!crawler}
        TitleComp={ViewTitleComp}
        ContentComp={ViewContentComp}
      />
    </>
  )
}

export default CrawlersData