import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Typography,
  Box
} from '@mui/material'
import { styled } from '@mui/material/styles'

const IconImage = styled('img')({
  width: 64,
  height: 64
})

const Banner = (props) => {
  const { iconSrc, title, subtitle, btnLabel, onClick } = props

  return (
    <Box
      component='div'
      sx={theme => ({
        height: 96,
        width: '100%',
        p: theme.spacing(3, 3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'background.300'
      })}
    >
      <Box
        component='div'
        sx={{
          display: 'flex'
        }}
      >
        <IconImage src={iconSrc} />
        <Box
          component='div'
          sx={theme => ({
            m: theme.spacing(0, 1, 0, 2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          })}
        >
          <Typography
            component='p'
            variant='subtitle2'
            sx={{ color: 'common.white' }}
          >
            {title}
          </Typography>
          <Typography
            component='p'
            variant='subtitle1'
            sx={{ color: 'common.white' }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <Button
        variant='contained'
        color='primary'
        onClick={onClick}
      >
        {btnLabel}
      </Button>
    </Box>
  )
}

Banner.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default Banner
