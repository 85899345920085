export const LOGIN_DEV = '/auth/login/?local_front=1'
export const LOGIN = '/auth/login/'
export const LOGOUT = '/auth/logout/'
export const USER = '/auth/user/'
export const SAMPLES = '/api/samples/'
export const SAMPLE = '/api/sample/'
export const SAMPLES_DOMAINS = '/api/samples/domains/'
export const LANGUAGES = '/api/languages/'
export const SAMPLES_USERS = '/api/samples/users/'
export const PREDICTORS_USERS = '/api/predictors/users/'
export const CRAWLER_BUILD = '/api/crawls/'
export const PREDICTORS = '/api/predictors/'
export const PREDICTOR_MERGE = '/api/predictors/merge/'
export const PREDICTOR_TEST = (id) => `/api/predictors/${id}/predict/`
export const PREDICTOR_COPY = (id) => `/api/predictors/${id}/copy/`
export const PREDICTOR_DOWNLOAD = (id) => `/api/predictors/${id}/download/`
export const TASKS = '/api/tasks/'
export const TASK_DELETE_OR_CHANGE = (id) => `/api/tasks/${id}/`
