import React, { useContext } from 'react'
import { AppBar } from '@mui/material'

import AppMenu from './AppMenu'
import ActionBar from './ActionBar'
import { PageContext } from '../../utils/helpers'

const Header = () => {
  const { actionBarState: { showBar } } = useContext(PageContext)

  return (
    <AppBar
      position='static'
      sx={{ height: 80, backgroundColor: 'background.300', boxShadow: 'none' }}
    >
      {showBar
        ? (<ActionBar />)
        : (<AppMenu />)
      }
    </AppBar>
  )
}

export default Header