import React, { useState, useCallback, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DoneIcon from '@mui/icons-material/Done'
import { alpha } from '@mui/material/styles'
import {
  Checkbox,
  MenuList,
  MenuItem,
  Typography,
  Box,
  Button
} from '@mui/material'

import { useOnClickOutside } from '../../hooks'
import { emptyFn } from '../../utils/helpers'

const GenericSelect = (props) => {
  const {
    sxButton,
    sxMenu,
    options,
    multiple,
    disabled,
    label,
    name,
    cancelLabel,
    submitLabel,
    onChange,
    showSelectedIcon,
    value: valueNode
  } = props
  const [multipleValues, setMultipleValues] = useState(valueNode)
  const [anchorElUser, setAnchorElUser] = useState(null)
  const menuRef = useRef(null)
  const valueNodeRef = useRef(valueNode)
  const labelValue = label || options.find(el => el?.value === valueNode)?.label

  useEffect(() => {
    if (multiple) {
      valueNodeRef.current = valueNode
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueNode])

  const onClickOutside = useCallback(() => {
    if (!!anchorElUser) {
      multiple && setMultipleValues(valueNodeRef?.current)
    }
    setAnchorElUser(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleValues])

  useOnClickOutside(menuRef, onClickOutside)

  const handleChange = (value) => {
    onChange({ value, name })
    setAnchorElUser(null)
  }

  const handleMultipleChange = (value) => {
    const valIdx = multipleValues.findIndex(el => el === value)
    let newValue = valIdx > -1
      ? [
        ...multipleValues.slice(0, valIdx),
        ...multipleValues.slice(valIdx + 1)
      ]
      : [
        ...multipleValues,
        value
      ]

    setMultipleValues(newValue)
  }

  const onClear = () => {
    setMultipleValues([])
    onChange({ value: [], name })
    setAnchorElUser(null)
  }

  const onSubmit = () => {
    onChange({ value: multipleValues, name })
    setAnchorElUser(null)
  }

  const onHandleClick = (event) => {
    setAnchorElUser(!!anchorElUser ? null : event.currentTarget)
  }

  const computeHeight = () => {
    if (window && window?.screen && anchorElUser && !showSelectedIcon) {
      const elPosition = anchorElUser?.getBoundingClientRect()
      const containerHeight = window?.innerHeight - elPosition?.y - elPosition.height - 40
      return multiple ? containerHeight - 66 : containerHeight
    }

    return 'auto'
  }

  return (
    <Box ref={menuRef} sx={{ position: 'relative' }}>
      <Button
        disabled={disabled}
        variant='outlined'
        sx={theme => ({
          ...theme?.typography?.subtitle1,
          ...(!!anchorElUser
          ? { borderColor: 'grey.300' } : {}),
          backgroundColor: 'common.white',
          ...(sxButton || {})
        })}
        endIcon={(
          <KeyboardArrowDownIcon
            fontSize='small'
            sx={{
              color: disabled ? 'grey.300' : 'secondary.main',
              mr: 0.5,
              ...(!!anchorElUser ? { transform: 'rotate(180deg)' } : {})
            }}/>
        )}
        onClick={onHandleClick}
      >
        {labelValue}
      </Button>
      {Boolean(anchorElUser) ? (<Box
        sx={theme => ({
          mt: 1,
          position: 'absolute',
          right: 0,
          width: 'auto',
          height: 'auto',
          minWidth: 200,
          minHeight: 104,
          overflow: 'visible',
          border: '1px solid',
          borderColor: theme.palette.grey[400],
          borderRadius: '2px',
          boxShadow: theme.shadows[theme.shadows?.length - 2],
          zIndex: theme.zIndex.tooltip,
          backgroundColor: 'common.white',
          ...(sxMenu || {})
        })}
      >
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            maxHeight: computeHeight(),
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          <MenuList variant='menu'>
            {options.map((option) => (
              <MenuItem
                key={option?.value}
                onClick={multiple
                  ? () => handleMultipleChange(option?.value)
                  : () => handleChange(option?.value)
                }
                sx={theme => ({
                  p: theme.spacing(1, 2, 1, 1.75),
                  height: 40,
                  '&.Mui-selected': {
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.common.black, 0.04)
                    },
                    '&:focus': {
                      backgroundColor: 'transparent'
                    }
                  }
                })}
              >
                {multiple ? <Checkbox
                  sx={{pl: 0}}
                  checked={multipleValues.indexOf(option?.value) > -1}
                  color='secondary'
                /> : null}
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                  <Typography
                    component='p'
                    textAlign='left'
                    variant='subtitle1'
                  >
                    {option?.label}
                  </Typography>
                  {showSelectedIcon && !multiple && (valueNode === option?.value) ? (
                    <DoneIcon fontSize='small' sx={{ color: 'secondary.main', p: 0 }} />
                  ) : null}
                </Box>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
        {multiple && (
          <Box
            sx={theme => ({
              width: '100%',
              border: `1px solid ${theme.palette.grey[400]}`,
              borderLeft: 0,
              borderRight: 0,
              p: theme.spacing(1.5, 2),
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: theme.palette.common.white
            })}
          >
            <Button
              variant={'outlined'}
              onClick={onClear}
            >
              {cancelLabel}
            </Button>
            <Button
              variant='contained'
              onClick={onSubmit}
            >
              {submitLabel}
            </Button>
          </Box>
        )}
      </Box>) : null}
    </Box>
  )
}

GenericSelect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  sxButton: PropTypes.object,
  sxMenu: PropTypes.object,
  showSelectedIcon: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

GenericSelect.defaultProps = {
  options: [],
  cancelLabel: 'Clear',
  submitLabel: 'Save',
  onChange: emptyFn
}

export default GenericSelect