import React, { useState, useContext, useRef, useCallback, useEffect } from 'react'
import sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Button,
  Typography,
  Box
} from '@mui/material'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import TaskListElement from './draggableDropdown/TaskListElement'
import DraggableTask from './draggableDropdown/DraggableTask'
import GenericDialog from './GenericDialog'
import { fetchApi, PageContext, reorder } from '../../utils/helpers'
import { useOnClickOutside } from '../../hooks'
import {
  CANCEL_CRAWL_TITLE,
  CANCEL_CRAWL_INFO,
  CANCEL_PREDICTOR_TITLE,
  CANCEL_PREDICTOR_INFO,
  KEEP_LABEL,
  CANCEL_LABEL,
  CRAWL,
  DELETE,
  PATCH,
  TASK_DELETE_OR_CHANGE
} from '../../constants'

const statuses = {
  running: 'success.main',
  stopped: 'error.main'
}

const DraggableDropdownList = (props) => {
  const { handleErrorMsg } = useContext(PageContext)
  const { currentStatus, queueTasks, startedTask } = props
  const [open, setOpen] = useState(null)
  const [queueList, setQueueList] = useState([...(queueTasks || [])])
  const [removeItem, setRemoveItem] = useState(null)
  const [taskNo, setTaskNo] = useState(queueList?.length + (startedTask ? 1 : 0))
  const [disableRemoveBtn, setDisableRemoveBtn] = useState(false)
  const listRef = useRef(null)
  useEffect(() => {
    setQueueList(queueTasks)
    setTaskNo(queueTasks?.length + (startedTask ? 1 : 0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueTasks?.length, startedTask?.id])

  const onClickOutside = useCallback(() => {
    setOpen(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useOnClickOutside(listRef, onClickOutside)

  const handleOpen = (event) => {
    setOpen(!!open ? null : event.currentTarget)
  }

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newOrder = reorder(
      queueList,
      result.source.index,
      result.destination.index
    )
    const item = queueList[result.source.index]
    setQueueList(sortBy(queueList.map((el, idx) => {
      if (idx === result.source.index) {
        el.order = newOrder
      }
      return el
    }), 'order'))

    const res = await fetchApi(TASK_DELETE_OR_CHANGE(item?.id), PATCH, { order: newOrder })
    if (res?.error) {
      handleErrorMsg(res?.error)
    }
  }

  const onRemoveItem = async () => {
    const idx = queueList.findIndex(el => el?.id === removeItem?.id)
    setDisableRemoveBtn(true)

    setQueueList([
      ...queueList.slice(0, idx),
      ...queueList.slice(idx + 1, queueList.length)
    ])
    setTaskNo(taskNo - 1)
    const res = await fetchApi(TASK_DELETE_OR_CHANGE(removeItem?.id), DELETE)

    if (res?.error) {
      handleErrorMsg(res?.error)
    }

    setRemoveItem(null)
    setDisableRemoveBtn(false)
  }

  const onRemove = (id) => {
    const task = startedTask?.id === id ? startedTask : queueList.find(el => el?.id === id)
    setRemoveItem(task)
  }

  const onCancelRemove = () => setRemoveItem(null)

  return taskNo ? (
    <Box ref={listRef} sx={{ position: 'relative' }}>
      <Button
        variant='outlined'
        onClick={handleOpen}
        sx={{
          borderColor: 'grey.200',
          color: 'common.white',

        }}
      >
        <FiberManualRecordIcon
          fontSize='small'
          sx={{
            color: statuses[currentStatus],
            mr: 1,
            width: 12,
            height: 12
          }}
        />
        {`${(taskNo)} ${taskNo > 1  ? 'Tasks' : 'Task'} in Queue`}
        <KeyboardArrowDownIcon
          fontSize='small'
          sx={{
            color: 'common.white',
            ml: 1.25,
            ...(!!open ? { transform: 'rotate(180deg)' } : {})
          }}
        />
      </Button>
      {Boolean(open) ? (
        <Box
          sx={theme => ({
            mt: 1,
            p: 3,
            position: 'absolute',
            left: '-100%',
            width: 558,
            maxHeight: 542,
            overflow: 'auto',
            border: '1px solid',
            borderColor: theme.palette.grey[400],
            borderRadius: '2px',
            boxShadow: theme.shadows[theme.shadows?.length - 1],
            zIndex: theme.zIndex.drawer,
            backgroundColor: 'common.white'
          })}
        >
            {startedTask ? (
              <Box>
                <Typography component='p' variant='subtitle2'>In Progress</Typography>
                <TaskListElement
                  {...startedTask}
                  onRemove={onRemove}
                />
              </Box>) : null}
            {queueList?.length ? (
              <>
                <Typography
                  component='p'
                  variant='subtitle2'
                  sx={{ mt: startedTask ? 2 : 0 }}
                >
                  Next Tasks
                </Typography>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='droppable'>
                    {(provided) => (
                      <>
                        <Box
                          component='div'
                          {...(provided?.droppableProps || {})}
                          ref={provided.innerRef}
                        >
                          {
                            queueList.map((el, idx) => (
                              <DraggableTask
                                key={el?.id}
                                draggableId={`${el?.id}-${el?.method}-${idx}`}
                                index={idx}
                                {...el}
                                onRemove={onRemove}
                              />)
                            )
                          }
                        </Box>
                        {provided?.placeholder}
                      </>)
                    }
                  </Droppable>
                </DragDropContext>
              </>
            ) : null}
        </Box>
      ) : null}
      <GenericDialog
        open={!!removeItem}
        onClose={onCancelRemove}
        title={removeItem?.method === CRAWL ? CANCEL_CRAWL_TITLE : CANCEL_PREDICTOR_TITLE}
        text={removeItem?.method === CRAWL ? CANCEL_CRAWL_INFO : CANCEL_PREDICTOR_INFO}
        cancelLabel={KEEP_LABEL}
        submitLabel={CANCEL_LABEL}
        onCancel={onCancelRemove}
        onSubmit={onRemoveItem}
        disableSubmitBtn={disableRemoveBtn}
      />
    </Box>
  ) : null
}

DraggableDropdownList.propTypes = {
  currentStatus: PropTypes.string,
  queueTasks: PropTypes.array,
  startedTask: PropTypes.object
}

DraggableDropdownList.defaultProps = {
  currentStatus: 'running',
  queueTasks: []
}

export default DraggableDropdownList
