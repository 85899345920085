import React, { useState, useCallback, useRef } from'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Box,
  MenuList,
  MenuItem,
  IconButton
} from '@mui/material'
import { emptyFn } from '../../utils/helpers'
import { useOnClickOutside } from '../../hooks'

const MenuIcon = (props) => {
  const { children, options, actions, sx, onChange } = props
  const [anchorElUser, setAnchorElUser] = useState(null)
  const menuRef = useRef(null)

  const onClickOutside = useCallback(() => {
    setAnchorElUser(null)
    onChange(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useOnClickOutside(menuRef, onClickOutside)

  const handleClickUserMenu = (event) => {
    setAnchorElUser(!!anchorElUser ? null : event.currentTarget)
    onChange(!!anchorElUser ? false : true)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
    onChange(false)
  }

  const handleAction = (action) => {
    actions[action]()
    handleCloseUserMenu()
  }

  return (
    <Box ref={menuRef} sx={{ position: 'relative' }}>
      <IconButton
        onClick={handleClickUserMenu}
        {...(sx ? { sx } : {})}
      >
        {children}
      </IconButton>
      {Boolean(anchorElUser) ? (<Box
        sx={theme => ({
          mt: 1,
          position: 'absolute',
          right: 0,
          width: 'auto',
          height: 'auto',
          minWidth: 200,
          overflow: 'visible',
          border: '1px solid',
          borderColor: theme.palette.grey[400],
          borderRadius: '2px',
          boxShadow: theme.shadows[theme.shadows?.length - 2],
          zIndex: theme.zIndex.tooltip,
          backgroundColor: 'common.white'
        })}
      >
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          <MenuList variant='menu'>
            {options.map((option) => (
              <MenuItem key={option?.value} onClick={() => handleAction(option?.value)}>
                <Typography
                  component='p'
                  textAlign='left'
                  variant='subtitle1'
                >
                  {option?.label}
                </Typography>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      </Box>) : null}
    </Box>
  )
}

MenuIcon.propTypes = {
  children: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  actions: PropTypes.object,
  sx: PropTypes.object,
  onChange: PropTypes.func
}

MenuIcon.defaultProps = {
  options: [],
  actions: {},
  onChange: emptyFn
}

export default MenuIcon