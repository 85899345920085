import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import FilterListIcon from '@mui/icons-material/FilterList'
import MergeIcon from '@mui/icons-material/Merge'
import { Box, Button, Badge } from '@mui/material'

import Search from './Search'
import { PAGES } from '../../constants'
import { emptyFn, PageContext } from '../../utils/helpers'

const SearchBar = (props) => {
  const {
    setPage,
    setFiltersProperties,
    filtersState: { appliedFilters, samples }
  } = useContext(PageContext)
  const { onSearch, mergePredictors, resultsNo, searchSx } = props
  const isLangsApplied =  samples
    ? get(appliedFilters, 'langs.length', 0) > 1
    : get(appliedFilters, 'langs.length', false)
  const areFiltersApplied = appliedFilters?.date
    || get(appliedFilters, 'users.length', false)
    || get(appliedFilters, 'sizes.length', false)
    || isLangsApplied

  const handleFilter = () => setFiltersProperties({ showFilters: true })

  const handleMerge = () => setPage(PAGES.PREDICTORS_MERGE)

  return (
    <Box sx={theme => ({
      display: 'flex',
      padding: theme.spacing(1.5, 2),
      width: '100%',
      borderBottom: `1px solid ${theme.palette.background[100]}`
    })}
    >
      <Search resultsNo={resultsNo} onChange={onSearch} sx={{ width: 280, mr: 1.5, ...searchSx }} />
      <Button
        variant='outlined'
        sx={{
          px: 2,
          mr: 1.5,
          '& .MuiButton-startIcon': {
            mr: 0
          }
        }}
        startIcon={(
          <Badge
            color='primary'
            variant='dot'
            invisible={!areFiltersApplied}
            sx={{
              '& .MuiBadge-anchorOriginTopRightRectangular': {
                top: 5,
                right: 7,
                width: 10,
                height: 10,
                borderRadius: 5,
                border: '2px solid white'
              }
            }}
          >
            <FilterListIcon
              fontSize='small'
              sx={{ color: 'secondary.main', mr: 0.3 }}
            />
          </Badge>
        )}
        onClick={handleFilter}
      >
        Filters
      </Button>
      {mergePredictors && (
        <Button
          variant='outlined'
          startIcon={(
            <MergeIcon
              fontSize='small'
              sx={{ color: 'secondary.main', mr: 0.3 }}/>
          )}
          onClick={handleMerge}
        >
          Merge Predictors
        </Button>
      )}
    </Box>
  )
}

SearchBar.propTypes = {
  onSearch: PropTypes.func,
  resultsNo: PropTypes.number,
  mergePredictors: PropTypes.bool,
  searchSx: PropTypes.object
}

SearchBar.defaultProps = {
  onSearch: emptyFn,
  searchSx: {}
}

export default SearchBar