export const PAGES = {
  MAIN: 'main',
  HELP: 'help',
  CRAWL_CONFIGURE: 'crawlConfigure',
  PREDICTOR_BUILD: 'predictorBuild',
  CRAWLERS_SAMPLE: 'crawlersSample',
  PREDICTORS_MERGE: 'mergePredictors',
  PREDICTOR_VIEW: 'viewPredictor',
  PREDICTOR_EDIT: 'editPredictor',
  PREDICTOR_TEST: 'testPredictor',
  PREDICTOR_MERGE: 'mergePredictor',
  PREDICTOR_MERGE_CONFIGURATOR: 'mergeConfiguratorPredictor'
}

export const MAIN_TABS = {
  SAMPLES: 'samples',
  PREDICTORS: 'predictors'
}