import React, { useEffect, useState, useContext, useRef } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import get from 'lodash/get'
import {
  Grid,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography,
  Box
} from '@mui/material'

import GlobalStore from '../../utils/store'
import PageLayout from '../containers/PageLayout'
import { GenericSelect, GenericTextArea } from '../common'
import { PredictorConfigNameRow } from './mergeComponents'
import { PageContext, fetchApi } from '../../utils/helpers'
import { PAGES, PREDICTOR_MERGE, POST, LANGUAGE_OPTIONS } from '../../constants'

const options = [
  {
    value: 1,
    label: `Strong bias for Predictor 1`
  },
  {
    value: 0.75,
    label: `Moderate bias for Predictor 1`
  },
  {
    value: 0.5,
    label: 'No Bias'
  },
  {
    value: 0.25,
    label: `Moderate bias for Predictor 2`
  },
  {
    value: 0,
    label: `Strong bias for Predictor 2`
  }
]
const rowStyle = { textAlign: 'left' }
const selectMenuStyle = { width: '100%' }

const MergeConfigurator = () => {
  const predictorsData = get(GlobalStore?.getLocal(), PAGES.PREDICTOR_MERGE_CONFIGURATOR, null)
  const languages = get(GlobalStore.getLocal(), LANGUAGE_OPTIONS, []) || []
  const {
    setPage,
    setActionBarProperties,
    actionBarState: { disableAction },
    handleErrorMsg
  } = useContext(PageContext)
  const [state, setState] = useState({
    name: '',
    description: '',
    language: predictorsData?.language || null,
    bias: options[2]?.value || null
  })
  const stateRef = useRef(state)
  const predictorsDataRef = useRef(predictorsData)
  let mounted = useRef(null)

  useEffect(() => {
    if (!mounted?.current) {
      setActionBarProperties({
        showBar: true,
        onCancel,
        onAction,
        cancelLabel: 'Cancel',
        actionLabel: 'Merge',
        disableAction: !state?.name
      })
      mounted.current = true
    } else {
      const disableActionState = !state?.name

      if (disableActionState !== disableAction) {
        setActionBarProperties({ disableAction: disableActionState })
      }
    }

    if (predictorsData) {
      predictorsDataRef.current = predictorsData
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.name, predictorsData])

  const onCancel = () => {
    if (predictorsDataRef?.current?.view) {
      GlobalStore.setData({
        id: predictorsDataRef?.current?.predictors[0]?.id },
        PAGES.PREDICTOR_VIEW
      )
    }
    GlobalStore.setData(null, PAGES.PREDICTOR_MERGE_CONFIGURATOR)
    setPage(predictorsDataRef?.current?.view ? PAGES.PREDICTOR_VIEW : PAGES.MAIN)
    setActionBarProperties({ showBar: false })
  }

  const onAction = async () => {
    const body = {
      predictor_1: get(predictorsDataRef, 'current.predictors[0]', {})?.id,
      predictor_2: get(predictorsDataRef, 'current.predictors[1]', {})?.id,
      name: get(stateRef, 'current.name', ''),
      description: get(stateRef, 'current.description', ''),
      bias: get(stateRef, 'current.bias', options[2]?.value)
    }
    const mergeRes = await fetchApi(PREDICTOR_MERGE, POST, body)
    if (!mergeRes?.error) {
      if (predictorsDataRef?.current?.view) {
        GlobalStore.setData({
            id: predictorsDataRef?.current?.predictors[0]?.id },
          PAGES.PREDICTOR_VIEW
        )
      }
      GlobalStore.setData(null, PAGES.PREDICTOR_MERGE_CONFIGURATOR)
      setPage(predictorsDataRef?.current?.view ? PAGES.PREDICTOR_VIEW : PAGES.MAIN)
      setActionBarProperties({ showBar: false })
    } else {
      handleErrorMsg(mergeRes?.error)
    }
  }

  const handleChange = (e) => {
    const { value, name } = e?.target || e
    const newState = {
      ...state,
      [name]: value
    }

    setState(newState)
    stateRef.current = newState
  }

  return (
    <PageLayout title='Configure Merged Predictor'>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12} sx={rowStyle}>
          {predictorsData?.predictors?.map((el, idx) => (
            <PredictorConfigNameRow key={`predictor-row-${idx}`} index={idx +1} name={el?.name} />
          ))}
        </Grid>
        <Grid item xs={12} sx={rowStyle}>
          <InputLabel required htmlFor='name'>Name</InputLabel>
          <OutlinedInput
            name='name'
            placeholder='Type name...'
            value={state?.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sx={rowStyle}>
          <InputLabel htmlFor='description'>Description</InputLabel>
          <GenericTextArea
            name='description'
            placeholder='Type description...'
            value={state?.description}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sx={rowStyle}>
          <InputLabel htmlFor='language'>Language</InputLabel>
          <GenericSelect
            name='language'
            disabled
            options={languages}
            value={state?.language}
            label={state?.language
              ? languages.find(el => el?.value === state?.language)?.label
              : 'Select language...'
            }
            sxButton={{ width: '100%', justifyContent: 'space-between' }}
            sxMenu={selectMenuStyle}
          />
        </Grid>
        <Grid item xs={12} sx={rowStyle}>
          <Box sx={{display: 'flex', alignItems: 'center', mb: 0.5}}>
            <Typography component='p' variant='subtitle2'>Bias</Typography>
            <Tooltip
              title={`To what extent do you want the resulting
               predictor to resemble the original predictors?`}
              placement='bottom-start'>
              <InfoIcon sx={{ color: 'grey.300', ml: 0.5, cursor: 'pointer' }}/>
            </Tooltip>
          </Box>
          <GenericSelect
            name='bias'
            showSelectedIcon
            options={options}
            value={state?.bias}
            label={state?.bias !== null
              ? options.find(el => el?.value === state?.bias)?.label
              : 'Select bias...'
            }
            onChange={handleChange}
            sxButton={{ width: '100%', justifyContent: 'space-between' }}
            sxMenu={selectMenuStyle}
          />
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default MergeConfigurator