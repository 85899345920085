import React from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import { alpha } from '@mui/material/styles'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  IconButton
} from '@mui/material'

const GenericDialog = (props) => {
  const {
    open,
    onClose,
    title,
    text,
    onCancel,
    onSubmit,
    cancelLabel,
    submitLabel,
    Comp,
    blurBackground,
    smallWidth,
    disableSubmitBtn
  } = props
  const isCancel = onCancel && cancelLabel
  const isSubmit = onSubmit && submitLabel

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: `generic-dialog ${smallWidth ? 'generic-dialog-small' : ''}`
      }}
      sx={theme => ({
        '& .MuiDialogContent-root': {
          color: 'text.primary'
        },
        '& .MuiDialog-container': {
          backgroundColor: blurBackground
            ? alpha(theme.palette.background[300], 0.9)
            : alpha(theme.palette.background[300], 0.3),
          ...(blurBackground ? { backdropFilter: 'blur(40px)' } : {})
        }
      })}
      transitionDuration={0.4}
    >
      <Box
        component='div'
        sx={theme => ({ p: Comp ? theme.spacing(5) : theme.spacing(6, 5, 0, 5) })}
      >
        {!blurBackground ? (<IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 21,
            top: 21
          }}
        >
          <CloseIcon fontSize='medium' sx={{color: 'text.primary'}}/>
        </IconButton>) : null}
        {Comp ? <Comp /> : (
          <DialogContent
            sx={theme => ({
              textAlign: 'center',
              p: theme.spacing(0, 0, 3, 0)
            })}
          >
            <Typography
              component='p'
              variant='h1'
              sx={{ mb: 1 }}
            >
              {title}
            </Typography>
            <Typography
              component='p'
              variant='subtitle1'
            >
              {text}
            </Typography>
          </DialogContent>
        )}
      </Box>
      {isCancel || isSubmit ? (
        <DialogActions sx={theme => ({
          borderTop: `1px solid ${theme.palette.grey[400]}`,
          p: theme.spacing(2, 5),
          display: 'flex',
          justifyContent: isCancel ? 'space-between' : 'flex-end'
        })}
        >
          {isCancel && (<Button
            variant='outlined'
            onClick={onCancel}
          >
            {cancelLabel}
          </Button>)}
          {isSubmit && (<Button
            disabled={disableSubmitBtn}
            variant={'contained'}
            onClick={onSubmit}
            color={isCancel ? 'error' : 'primary'}
            sx={isCancel ? {
              '&:hover': {
                backgroundColor: isCancel ? 'error.dark' : 'primary.main'
              },
              '&.Mui-disabled': {
                backgroundColor: isCancel ? 'error.dark' : 'primary.main'
              }
            } : {}}
          >
            {submitLabel}
          </Button>)}
        </DialogActions>
      ) : null}
    </Dialog>
  )
}

GenericDialog.propTypes = {
  blurBackground: PropTypes.bool,
  disableSubmitBtn: PropTypes.bool,
  smallWidth: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  Comp: PropTypes.elementType,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default GenericDialog
