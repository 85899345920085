export const CANCEL_CRAWL_TITLE = 'Cancel Crawl?'
export const CANCEL_CRAWL_INFO = `Cancelling a crawler will stop producing 
new samples but keep the generated ones. Cancel task?`
export const CANCEL_PREDICTOR_TITLE = 'Cancel Predictor?'
export const CANCEL_PREDICTOR_INFO = `Cancelling will discard all progress for this task.
 The predictor will not be saved. Cancel task?`
export const TASKS_LIMIT = 'Queue Limit Reached'
export const TASKS_LIMIT_INFO = `You’ve reached the maximum limit of 10 queued tasks.
 Try again later or delete an existing queued task.`
export const KEEP_LABEL = 'Keep'
export const CANCEL_LABEL = 'Cancel'
export const INFO_LABEL = 'Ok, Got It'
export const DELETE_FOREVER_LABEL = 'Delete Forever'
export const DELETE_PREDICTOR_TEXT = (name) => `Deleting predictor "${name}"
 will remove all data associated to it forever. Proceed anyway?`
export const QUEUE_LIMIT_TEXT = `You’ve reached the maximum limit of 100 queued tasks.
 Try again later or delete an existing queued task.`