import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Button, CircularProgress } from '@mui/material'
import { MACHINE, API_URL } from 'gatsby-env-variables'

import { LOGIN, LOGIN_DEV } from '../../constants'

import { BoxLogo } from '../../constants'

const LoginContent = (props) => {
  const { error, loading } = props

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }}
    >
      <BoxLogo/>
      <Typography component='p' variant='h1' sx={{mt: 3, mb: 1}}>{
        error ? 'Something went wrong...' : 'Welcome!'
      }</Typography>
      {loading ? (<CircularProgress/>) : (
        <>
          <Typography component='p' variant='subtitle1' sx={{mb: 2}}>
            {
              error
                ? 'Please try again!'
                : 'Please log in to access your Word Prediction Pipeline account.'
            }
          </Typography>
          <Button
            variant='contained'
            href={`${API_URL}${MACHINE === 'development' ? LOGIN_DEV : LOGIN}`}
          >
            Log In
          </Button>
        </>
      )}
    </Box>
  )
}

LoginContent.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool
}

export default LoginContent
