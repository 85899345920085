import React from 'react'
import PropTypes from 'prop-types'

import GenericCheckboxGroup from '../GenericCheckboxGroup'

const UsersFilter = (props) => {
  const { onChange, name, selected, options } = props

  return (
    <GenericCheckboxGroup
      name={name}
      items={options}
      selected={selected}
      onChange={onChange}
    />
  )
}

UsersFilter.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.array
}

UsersFilter.defaultProps = {
}

export default UsersFilter
