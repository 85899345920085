import React from 'react'
import PropTypes from 'prop-types'

import GenericRadioGroup from '../GenericRadioGroup'

const options = [
  { value: '1w', label: 'Last week' },
  { value: '2w', label: 'Last 2 weeks' },
  { value: '1m', label: 'Last month' },
  { value: '2m', label: 'Last 2 months' },
  { value: '6m', label: 'Last 6 months' },
  { value: '1y', label: 'Last year' }
]

const DateFilter = (props) => {
  const { onChange, name, value } = props

  return (
    <GenericRadioGroup
      name={name}
      items={options}
      value={value}
      onChange={onChange}
    />
  )
}

DateFilter.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string
}

export default DateFilter
