import React from 'react'
import PropTypes from 'prop-types'
import isArray from 'lodash/isArray'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
  FormControlLabel
} from '@mui/material'

const GenericAccordion = (props) => {
  const {
    name,
    title,
    detailsText,
    DetailsComp,
    checked,
    indeterminate,
    handleCheckbox,
    expanded,
    value,
    detailsSx,
    whiteSummary,
    accordionSx,
    checkboxName,
    onChange
  } = props
  const isExpanded = isArray(expanded) ? expanded.indexOf(value) > -1 : value === expanded

  const handleChange = () => {
    onChange({ name, value })
  }

  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      sx={{
        '&.MuiAccordion-root': {
          borderRadius: '2px',
          ...(isExpanded && whiteSummary ? {
            borderRadius: 0,
            border: '1px solid',
            borderColor: 'grey.400'
          } : {}),
          ...(accordionSx || {})
        },
        '&:before': {
          display: 'none',
        }
      }}
      {...(onChange ? { expanded: isExpanded, onChange: handleChange } : {})}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon sx={{ color: 'grey.300' }} />}
        sx={{
          backgroundColor: whiteSummary && !(isExpanded)
            ? 'common.white' : 'background.100',
          borderRadius: '2px',
          minHeight: 40,
          height: 40
        }}
      >
        {handleCheckbox ? (<FormControlLabel
          sx={{ ml: -1 }}
          label={<Typography component='p' variant='subtitle1'>{title}</Typography>}
          control={
            <Checkbox
              color='secondary'
              checked={checked}
              name={checkboxName}
              indeterminate={indeterminate}
              onChange={handleCheckbox}
            />
          }
        />) : (
          <Typography component='p' variant='subtitle2'>{title}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails sx={detailsSx ? detailsSx : {}}>
        {detailsText && (
          <Typography
            component='p'
            variant='subtitle1'
            sx={{ textAlign: 'left' }}
          >
            {detailsText}
          </Typography>)}
        {DetailsComp && (<DetailsComp />)}
      </AccordionDetails>
    </Accordion>
  )
}

GenericAccordion.propTypes = {
  name: PropTypes.string,
  checkboxName: PropTypes.string,
  title: PropTypes.string.isRequired,
  detailsText: PropTypes.string,
  DetailsComp: PropTypes.elementType,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  handleCheckbox: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  onChange: PropTypes.func,
  detailsSx: PropTypes.object,
  whiteSummary: PropTypes.bool,
  accordionSx: PropTypes.object
}


export default GenericAccordion