import omit from 'lodash/omit'
import set from 'lodash/set'

export const storeName = 'wordPrediction'
const isBrowser = typeof window !== 'undefined'
let storeData = {}

class GlobalStore {
  static resetData(item) {
    storeData = {}

    if (isBrowser) {
      if (item) {
        const localStorageData = this.getLocal()
        const newData = omit(localStorageData, item)
        storeData = newData
        window?.localStorage?.setItem(storeName, JSON.stringify(newData))
      } else {
        window?.localStorage?.removeItem(storeName)
      }
    }
  }

  static setData(data, path) {
    if (isBrowser) {
      if (path) {
        const localStorageData = this.getLocal()
        set(localStorageData, path, data)

        window?.localStorage?.setItem(storeName, JSON.stringify(localStorageData))
        set(storeData, path, data)
      } else {
        const newData = {
          ...storeData,
          ...data,
        }

        window?.localStorage?.setItem(storeName, JSON.stringify(newData))
        storeData = newData
      }
    }
  }

  static getLocal() {
    if (isBrowser) {
      const localData = window?.localStorage?.getItem(storeName)

      return localData ? JSON.parse(localData) : {}
    }

    return {}
  }

  static getData() {
    return storeData
  }
}

storeData = GlobalStore.getLocal()

export default GlobalStore
