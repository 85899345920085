import React, { useContext } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Grid, Typography, Button } from '@mui/material'

import PredictorConfigTextArea from './PredictorConfigTextArea'
import AbbreviatedExpansion from './AbbreviatedExpansion'
import { RowDropdownWithLabel } from '../../common'
import { PredictorOptionsContext } from '../../../utils/helpers'
import { lexiconData, lossData } from '../../../data'

const AdvancedOptions = () => {
  const {
    id,
    lexicon,
    loss,
    allowed,
    blocked,
    abbreviatedExpansions,
    onChange
  } = useContext(PredictorOptionsContext)

  const handleAbbreviatedExpansionChange = (value, idx) => {
    const newState = [...(abbreviatedExpansions || [])]
    newState[idx] = value

    onChange({
      name: 'abbreviatedExpansions',
      value: newState
    })
  }

  const handleAddAnotherClick = () => {
    onChange({
      name: 'abbreviatedExpansions',
      value: [
        ...(abbreviatedExpansions || []),
        {
          abbreviation: '',
          expansion: ''
        }
      ]
    })
  }

  return (
    <Grid container spacing={3} sx={{ p: 0, pt: 3 }}>
      <Grid item xs={12}>
        <RowDropdownWithLabel
          label='Include Lexicon'
          name='lexicon'
          disabled={!!id}
          options={lexiconData}
          infoText={`Include in the new predictor the full lexicon for the chosen language.`}
          onChange={onChange}
          value={lexicon}
        />
      </Grid>
      <Grid item xs={12}>
        <RowDropdownWithLabel
          label='Apply Loss'
          name='loss'
          disabled={!!id}
          options={lossData}
          infoText={`Apply a loss function to drop rare word combinations 
          (i.e. infrequent n-grams), making the new predictor significantly smaller in size.`}
          onChange={onChange}
          value={loss}
        />
      </Grid>
      <Grid item xs={12}>
        <PredictorConfigTextArea
          name='allowed'
          label='Allowed'
          infoText={`List of permitted predictor words. 
          Separate words using comma or space.`}
          placeholder='Type allowed words...'
          value={allowed}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <PredictorConfigTextArea
          name='blocked'
          label='Blocked'
          infoText={`List of prohibited predictor words. 
          Separate words using comma or space.`}
          placeholder='Type blocked words...'
          value={blocked}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          component='p'
          variant='subtitle2'
          sx={{ mb: 1.5 }}
        >
          Abbreviated Expansion
        </Typography>
        {
          (abbreviatedExpansions || [])?.map((el, idx) => (
            <AbbreviatedExpansion
              key={`abbreviated-expansion-${idx}`}
              {...(el || {})}
              idx={idx}
              onChange={handleAbbreviatedExpansionChange}
            />
          ))
        }
        <Button
          variant='outlined'
          startIcon={(
            <AddIcon
              fontSize='small'
              sx={{ color: 'common.black', mr: 0.5 }}/>
          )}
          onClick={handleAddAnotherClick}
        >
          Add Another
        </Button>
      </Grid>
    </Grid>
  )
}

export default AdvancedOptions