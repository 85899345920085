import React from 'react'
import PropTypes from 'prop-types'
import { Box, Radio, Typography } from '@mui/material'

const MergePredictorRow = (props) => {
  const { name, languages, description, onChange, selected, id } = props
  const isSelected = selected === id

  const handleClick = () => {
    onChange({ value: id })
  }

  return (
    <Box
      component='button'
      onClick={handleClick}
      sx={{
        cursor: 'pointer',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        outline: 'none',
        border: 0,
        borderRadius: 0.5,
        py: 1,
        backgroundColor: 'common.white',
        '&:hover': {
          backgroundColor: 'background.100'
        }
      }}
    >
      <Radio
        color='secondary'
        value={id}
        checked={isSelected}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: 64,
          minHeight: 64,
          borderRadius: '2px',
          backgroundColor: 'grey.100',
          mr: 2,
          ml: 0.75
        }}
      >
        <Typography
          component='p'
          variant='body2'
          sx={{ color: 'common.white' }}
        >
          {languages?.length ? languages[0]?.split(' (')[0] : 'No-Lang'}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '75%'
        }}
      >
        <Typography
          component='p'
          variant='subtitle2'
          sx={{ color: 'secondary.main' }}
        >
          {name}
        </Typography>
        <Typography
          component='p'
          variant='subtitle1'
          sx={{
            color: 'grey.300',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  )
}

MergePredictorRow.propTypes = {
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  languages: PropTypes.array,
  description: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func
}

export default MergePredictorRow