import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { emptyFn } from '../../utils/helpers'
import { TextAreaSvg } from '../../constants'

const StyledIcon = styled(TextAreaSvg)({
  position: 'absolute',
  right: 5,
  bottom: 12
})

const StyledTextAutosize = styled('textarea')(({ theme }) => ({
  ...theme.typography.subtitle1,
  padding: theme.spacing(1.5),
  minHeight: 192,
  width: '100%',
  resize: 'vertical',
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: 2,
  backgroundColor: theme.palette.common.white,
  color: theme.palette.secondary.main,
  '&:hover': {
    borderColor: theme.palette.grey[300],
    outline: 0
  },
  '&:focus': {
    borderColor: theme.palette.grey[300],
    outline: 0
  },
  '&::placeholder': {
    color: theme.palette.grey[300]
  },

  '&::-webkit-resizer': {
    display: 'none'
  }
}))

const GenericTextArea = (props) => {
  const { name, value, onChange, ...otherProps } = props

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <StyledTextAutosize
        name={name}
        value={value}
        onChange={onChange}
        style={{

        }}
        {...otherProps}
      />
      <StyledIcon />
    </Box>
  )
}

GenericTextArea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

GenericTextArea.defaultProps = {
  onChange: emptyFn
}

export default GenericTextArea