import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

const LinkComponent = styled('a')(({ theme }) => ({
  ...theme?.typography?.subtitle1,
  color: theme.palette.common.white,
  textDecoration: 'none'
}))


const Footer = () => (
  <Box
    sx={{
      position: 'static',
      minHeight: 160,
      width: '100%',
      pb: 5,
      mt: 7,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',
      backgroundColor: 'background.300'
    }}
  >
    <Typography
      component='p'
      variant='subtitle1'
      sx={{ color: 'common.white' }}
    >
      Designed and implemented by <LinkComponent
      href={'https://humaticlabs.com/'}
      target='_blank'
      rel='noreferrer'
    >
      Humatic Labs
    </LinkComponent>
    </Typography>
  </Box>
)

export default Footer