import React, { useState, useRef, useContext, useEffect } from 'react'
import get from 'lodash/get'
import { Box, Typography } from '@mui/material'

import GlobalStore from '../../utils/store'
import PageLayout from '../containers/PageLayout'
import { GenericTextArea } from '../common'
import { PageContext, fetchApi } from '../../utils/helpers'
import { PAGES, POST, PREDICTOR_TEST } from '../../constants'

const TestPredictor = () => {
  const { id, name, view } = get(GlobalStore.getLocal(), PAGES.PREDICTOR_TEST, null)
  const { setActionBarProperties, setPage, handleErrorMsg } = useContext(PageContext)
  const [testInput, setTestInput] = useState('')
  const [results, setResults] = useState([])
  const testInputRef = useRef(testInput)
  const fromViewPageRef = useRef({ id, view })
  let timeout = useRef(null)
  let mounted = useRef(null)

  useEffect(() => {
    if (!mounted?.current) {
      setActionBarProperties({
        showBar: true,
        onCancel: onAction,
        onAction,
        cancelLabel: 'Cancel',
        actionLabel: 'Done'
      })
      mounted.current = true
    }
    fromViewPageRef.current = { id, view }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, id])

  const onAction = () => {
    if (fromViewPageRef.current?.view) {
      GlobalStore.setData({ id: fromViewPageRef?.current?.id }, PAGES.PREDICTOR_VIEW)
    }
    setPage(fromViewPageRef.current?.view ? PAGES.PREDICTOR_VIEW : PAGES.MAIN)
    setActionBarProperties({ showBar: false })
  }

  const fetchPredictions = async () => {
    const result = await fetchApi(PREDICTOR_TEST(id), POST, { text: testInputRef?.current })
    if (!result?.error) {
      setResults(result?.results || [])
    } else {
      handleErrorMsg(result?.error)
    }
  }

  const handleChange = (e) => {
    const { value } = e?.target || e
    e.preventDefault()
    e.stopPropagation()
    setTestInput(value)
    testInputRef.current = value

    if (window) {
      clearTimeout(timeout?.current)
      timeout.current = window.setTimeout(() => {
       fetchPredictions()
      }, 400)
    }
  }

  return (
    <PageLayout
      title='Test Predictor'
      subtitle={`Type something to generate word predictions from predictor "${name}".`}
    >
      <Box
        sx={theme => ({ width: '100%', p: theme.spacing(3, 2, 2.5, 2) })}
      >
        <Box sx={{ mx: 1 }}>
          <GenericTextArea
            name='testInput'
            placeholder='Start typing...'
            value={testInput}
            onChange={handleChange}
          />
        </Box>
        {
          results && results?.length ? (
            <Box
              sx={{
                mt: 3,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderTop: '1px solid',
                borderColor: 'grey.400',
                textAlign: 'left'
              }}
            >
              {results.map((el, idx) => (
                <Typography
                  key={`result-element-${idx}`}
                  component='p'
                  variant='subtitle1'
                  sx={{ my: 1 }}
                >
                  {el}
                </Typography>
              ))}
            </Box>
          ) : null
        }
      </Box>
    </PageLayout>
  )
}

export default TestPredictor