import React from 'react'
import PropTypes from 'prop-types'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Typography, Tooltip } from '@mui/material'

import { GenericTextArea } from '../../common'

const PredictorConfigTextArea = (props) => {
  const { label, infoText, name, placeholder, value, onChange } = props

  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center', mb: 0.5}}>
        <Typography component='p' variant='subtitle2'>{label}</Typography>
        <Tooltip
          title={infoText}
          placement='bottom-start'>
          <InfoIcon sx={{ color: 'grey.300', ml: 0.5, cursor: 'pointer' }}/>
        </Tooltip>
      </Box>
      <GenericTextArea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </>
  )
}

PredictorConfigTextArea.propTypes = {
  label: PropTypes.string,
  infoText: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default PredictorConfigTextArea