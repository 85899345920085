import React, { useState } from 'react'
import PropTypes from 'prop-types'
import isArray from 'lodash/isArray'
import { Box, Typography, Button } from '@mui/material'

import PreviewCardRow from './PreviewCardRow'
import { PreviewDialog } from '../../common'

const PreviewCard = (props) => {
  const { title, content, placeholder, sxContainer } = props
  const [openView, setOpenView] = useState(false)

  const sxContentString = theme => ({ ...theme?.typography?.body1 })

  const handleView = () => setOpenView(!openView)

  return (
    <>
      <Box
        sx={{
          p: 3,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'common.white',
          textAlign: 'left',
          ...(sxContainer || {})
        }}
      >
        <Typography
          component='p'
          variant='subtitle2'
          sx={{ mb: 1 }}
        >
          {title}
        </Typography>
        {
          (!content || !content?.length) && placeholder ? (
            <Typography
              component='p'
              variant='subtitle1'
              sx={{ color: 'grey.300' }}
            >
              {placeholder}
            </Typography>
          ) : null
        }
        {isArray(content) ? (
          <Box sx={{ width: '100%' }}>
            {content.map((el, idx) => (<PreviewCardRow key={`preview-row-${idx}`} {...el} />))}
          </Box>
        ) : (
          <Typography
            component='p'
            variant='subtitle1'
            sx={{
              maxHeight: 72,
              display: '-webkit-box',
              wordBreak: 'break-word',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              webkitLineClamp: '3',
              webkitBoxOrient: 'vertical'
            }}
          >
            {content}
          </Typography>
        )}
        {content?.length > 232 ? (
          <Button
            variant='outlined'
            sx={{ mt: 2, width: 80 }}
            onClick={handleView}
          >
            View All
          </Button>
        ) : null}
      </Box>
      <PreviewDialog
        open={openView}
        title={title}
        contentString={isArray(content) ? '' : content}
        sxContentString={sxContentString}
        onClose={handleView}
      />
    </>
  )
}

PreviewCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  placeholder: PropTypes.string,
  sxContainer: PropTypes.object
}

export default PreviewCard