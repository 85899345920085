import React from 'react'
import PropTypes from 'prop-types'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { OutlinedInput, Box } from '@mui/material'

const AbbreviatedExpansion = (props) => {
  const { onChange, abbreviation, expansion, idx } = props

  const handleChange = (e) => {
    const { value, name } = e?.target || e

    onChange({
      abbreviation,
      expansion,
      [name]: value
    }, idx)
  }

  return (
    <Box sx={{ width: '100%', px: 0, pb: 1.5,  display: 'flex', alignItems: 'center' }}>
      <OutlinedInput
        name='abbreviation'
        value={abbreviation}
        onChange={handleChange}
      />
      <ArrowRightAltIcon sx={{ color: 'common.black', mx: 2, my: 0 }} />
      <OutlinedInput
        name='expansion'
        value={expansion}
        onChange={handleChange}
      />
    </Box>
  )
}

AbbreviatedExpansion.propTypes = {
  onChange: PropTypes.func,
  abbreviation: PropTypes.string,
  expansion: PropTypes.string,
  idx: PropTypes.number
}

export default AbbreviatedExpansion