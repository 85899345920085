import React from 'react'
import PropTypes from 'prop-types'

import GenericCheckboxGroup from '../GenericCheckboxGroup'

const LanguageFilter = (props) => {
  const { onChange, name, selected, options, disabledDefault } = props

  return (
    <GenericCheckboxGroup
      disabledDefault={disabledDefault}
      name={name}
      items={options}
      selected={selected}
      onChange={onChange}
    />
  )
}

LanguageFilter.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.array,
  disabledDefault: PropTypes.array
}

export default LanguageFilter
