import React, { useState, useEffect, useContext, useRef } from 'react'
import get from 'lodash/get'
import InfoIcon from '@mui/icons-material/Info'
import AddIcon from '@mui/icons-material/Add'
import {
  Grid,
  Box,
  Button,
  Tooltip,
  InputLabel
} from '@mui/material'

import GlobalStore from '../../utils/store'
import PageLayout from '../containers/PageLayout'
import { GenericTextArea, RowDropdownWithLabel } from '../common'
import { CrawlRadioButton } from './configurator'
import { PAGES, CRAWLER_BUILD, POST, LANGUAGE_OPTIONS } from '../../constants'
import { PageContext, fetchApi } from '../../utils/helpers'
import { crawlTypes, timeLimit, dataLimit } from '../../data'

const disabledCrawlTypes = [crawlTypes[1].value]

const ConfigureWebCrawl = () => {
  const languages = get(GlobalStore.getLocal(), LANGUAGE_OPTIONS, []) || []
  const {
    setPage,
    setActionBarProperties,
    actionBarState: { disableAction },
    handleErrorMsg
  } = useContext(PageContext)
  const [state, setState] = useState({
    crawlType: crawlTypes[0].value,
    time: timeLimit[0]?.value,
    data: dataLimit[0]?.value,
    languages: [],
    urls: '',
    annotation: false,
    annotationText: ''
  })
  let mounted = useRef(false)
  const stateRef = useRef(state)

  useEffect(() => {
    if (!mounted?.current) {
      setActionBarProperties({
        showBar: true,
        onCancel: () => {
          setPage(PAGES.MAIN)
          setActionBarProperties({ showBar: false })
        },
        onAction,
        cancelLabel: 'Cancel',
        actionLabel: 'Start Crawl',
        disableAction: disabledCrawlTypes.indexOf(state?.crawlType) > -1
          && !(state?.urls && state?.urls?.length)
      })
      mounted.current = true
    } else {
      const disableActionState = disabledCrawlTypes.indexOf(state?.crawlType) > -1
        && !(state?.urls && state?.urls?.length)

      if (disableActionState !== disableAction) {
        setActionBarProperties({ disableAction: disableActionState })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.crawlType, state?.urls])

  const onAction = async () => {
    const body = {
      type: stateRef?.current?.crawlType,
      time_limit: stateRef?.current?.time,
      data_limit: stateRef?.current?.data === 'noLimit' ? null : stateRef?.current?.data,
      languages: stateRef?.current?.languages,
      urls: stateRef?.current?.urls?.split('\n').filter(el => !!el),
      annotation: stateRef?.current?.annotationText
    }
    const result = await fetchApi(CRAWLER_BUILD, POST, body)

    if (!result?.error) {
      setPage(PAGES.MAIN)
      setActionBarProperties({ showBar: false })
    } else {
      handleErrorMsg(result?.error)
    }
  }

  const handleChange = (e) => {
    const { value, name } = e?.target || e
    const newState = {
      ...state,
      [name]: value
    }

    setState(newState)
    stateRef.current = newState
  }

  const handleAnnotationClick = () => setState({ ...state, annotation: true })

  return (
    <PageLayout title='Configure Web Crawl'>
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12} >
          {crawlTypes.map((crawlType, idx) => (
            <CrawlRadioButton
              key={`crawl-type-${idx}`}
              name='crawlType'
              checked={crawlType?.value === state?.crawlType}
              onChange={handleChange}
              {...crawlType}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <RowDropdownWithLabel
            label='Time Limit'
            name='time'
            options={timeLimit}
            onChange={handleChange}
            value={state?.time}
          />
          <RowDropdownWithLabel
            label='Data Limit'
            name='data'
            options={dataLimit}
            onChange={handleChange}
            value={state?.data}
            styles={{ mt: 1, mb: 1 }}
          />
          <RowDropdownWithLabel
            label='Target Languages'
            name='languages'
            options={languages}
            onChange={handleChange}
            value={state?.languages}
            selectLabel={state?.languages?.length
              ? `Languages (${state?.languages?.length})`
              : 'All Languages'
            }
            multiple
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
            <InputLabel
              required
              htmlFor='urls'
            >Start URLs</InputLabel>
            <Tooltip
              title={`Add start URLs to your crawl, one per line. 
           The crawler will initiate its search from these links.`}
              placement='bottom-start'>
              <InfoIcon sx={{color: 'grey.300', ml: 0.5, cursor: 'pointer'}}/>
            </Tooltip>
          </Box>
          <GenericTextArea
            name='urls'
            placeholder='Type or paste URLs'
            value={state?.urls}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'left' }}>
          {state?.annotation ? (
            <>
              <InputLabel htmlFor='annotationText'>Annotation</InputLabel>
              <GenericTextArea
                name='annotationText'
                placeholder='Type your annotations'
                value={state?.annotationText}
                onChange={handleChange}
              />
            </>
          ) : (
            <Button
              variant='outlined'
              startIcon={(
                <AddIcon
                  fontSize='small'
                  sx={{ color: 'common.black', mr: 0.5 }}/>
              )}
              onClick={handleAnnotationClick}
            >Add Annotation</Button>
          )}
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default ConfigureWebCrawl