import React, { useContext, useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import AbortController from 'abort-controller'
import { Grid, Box } from '@mui/material'

import LoadingPredictorRow from './LoadingPredictorRow'
import PredictorRow from './PredictorRow'
import { Banner, NoDataBox, SearchBar, GenericDialog } from '../common'
import { fetchApi, PageContext, parseObjToQueryParams, UserContext } from '../../utils/helpers'
import {
  PAGES,
  PREDICTORS,
  CANCEL_LABEL,
  DELETE_FOREVER_LABEL,
  DELETE_PREDICTOR_TEXT,
  PredictorsNoData,
  DELETE,
  GET
} from '../../constants'

const PredictorsData = () => {
  const {
    setPage,
    filtersState: { appliedFilters, samples },
    handleErrorMsg
  } = useContext(PageContext)
  const { user } = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [search, setSearch] = useState('')
  const [samplesNo, setSamplesNo] = useState(0)
  const [deletePredictor, setDeletePredictor] = useState(null)
  const [predictors, setPredictors] = useState(null)
  const [controller, setController] = useState(null)

  useEffect(() => {
    setLoading(true)
    fetchPredictors(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    search,
    appliedFilters?.users,
    appliedFilters?.langs,
    appliedFilters?.date,
    appliedFilters?.sizes
  ])

  const fetchPredictors = async (page, params = {}) => {
    const loadMore = page > 1
    let signal = null
    if (!loadMore) {
      const ctrl = new AbortController()
      setController(ctrl)
      signal = ctrl.signal
    }

    if (samples) {
      return
    }

    const newParams = {
      page: page || 1,
      search,
      ...appliedFilters,
      ...params
    }
    const result = await fetchApi(`${PREDICTORS}${parseObjToQueryParams(newParams)}`, GET, null, {}, signal)
    if (!result?.error) {
      setPredictors(loadMore
        ? [...(predictors || []), ...(result?.results || [])]
        : result?.results)
      setHasMore(!!result?.next)
      setSamplesNo(result?.count)
    }
    setLoading(false)
  }

  const handleContinueToSamples = () => setPage(PAGES.PREDICTOR_BUILD)

  const openDeleteDialog = (data) => setDeletePredictor(data)
  const closeDeleteDialog = () => setDeletePredictor(null)
  const onDelete = async () => {
    const result = await fetchApi(`${PREDICTORS}${deletePredictor?.id}/`, DELETE)
    if (result?.error) {
      handleErrorMsg(result?.error)
    }
    await fetchPredictors(1)
    setDeletePredictor(null)
  }

  const onSearch = (value) => {
    if (controller) {
      controller.abort()
      setController(null)
    }
    setSearch(value)
  }

  return (
    <>
      <Grid item xs={12}>
        <Banner
          iconSrc='predictor.png'
          title='Build a predictor'
          subtitle='Create and upload predictors into your apps to improve word prediction.'
          btnLabel='Build Predictor'
          onClick={handleContinueToSamples}
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={theme => ({
            width: '100%',
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.background[100]}`,
            borderRadius: '2px'
          })}
        >
          <SearchBar
            mergePredictors
            onSearch={onSearch}
            resultsNo={samplesNo}
          />
          {loading ?
            (<Box
                sx={theme => ({
                  width: '100%',
                  backgroundColor: theme.palette.common.white,
                  padding: theme.spacing(2.5, 5.5, 1.5, 2)
                })}
              >
                {Array.from({length: 3}, (v, k) => k).map(k => (
                  <LoadingPredictorRow key={`loading-row-${k}`}/>
                ))}
              </Box>
            ) : predictors?.length ? (
              <Grid container sx={{m: 0, px: 0, py: 1}}>
                <InfiniteScroll
                  pageStart={1}
                  style={{ width: '100%' }}
                  loadMore={fetchPredictors}
                  hasMore={hasMore}
                  loader={
                    <Box
                      key={`loader-infinite-scroll-predictor`}
                      sx={theme => ({ width: '100%', padding: theme.spacing(0, 2) })}
                    >
                      <LoadingPredictorRow />
                    </Box>
                  }
                >
                {
                  predictors.map((el, idx) => (
                    <PredictorRow
                      key={`predictor-row-${idx}`}
                      {...el}
                      handleDelete={openDeleteDialog}
                    />
                  ))
                }
                </InfiniteScroll>
              </Grid>
            ) : (
              (
                <NoDataBox
                  noBorder
                  Icon={PredictorsNoData}
                  subtitle='No predictors found'
                />
              ))
          }
        </Box>
      </Grid>
      <GenericDialog
        open={!!deletePredictor}
        onClose={closeDeleteDialog}
        title='Delete Predictor?'
        text={DELETE_PREDICTOR_TEXT(deletePredictor?.name)}
        cancelLabel={CANCEL_LABEL}
        submitLabel={DELETE_FOREVER_LABEL}
        onCancel={closeDeleteDialog}
        onSubmit={onDelete}
      />
    </>
  )
}

export default PredictorsData