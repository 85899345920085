import React from 'react'
import PropTypes from 'prop-types'

import GenericCheckboxGroup from '../GenericCheckboxGroup'
import { MAIN_TABS } from '../../../constants'

const samplesOptions = [
  { value: '-200b', label: '200 B and under' },
  { value: '200b-1kb', label: '200 B to 1 KB' },
  { value: '1kb-10kb', label: '1 KB to 10 KB' },
  { value: '10kb-100kb', label: '10 KB to 100 KB' },
  { value: '100kb-1mb', label: '100 KB to 1 MB' },
  { value: '1mb-', label: 'over 1 MB' }
]

const predictorOptions = [
  { value: '-1mb', label: '1 MB and under' },
  { value: '1mb-10mb', label: '1 MB to 10 MB' },
  { value: '10mb-20mb', label: '10 MB to 20 MB' },
  { value: '20mb-50mb', label: '20 MB to 50 MB' },
  { value: '50mb-100mb', label: '50 MB to 100 MB' },
  { value: '100mb-', label: 'over 100 MB' }
]

const SizeFilter = (props) => {
  const { onChange, name, selected, pageTab } = props

  return (
    <GenericCheckboxGroup
      name={name}
      items={pageTab === MAIN_TABS.SAMPLES ? samplesOptions : predictorOptions}
      selected={selected}
      onChange={onChange}
    />
  )
}

SizeFilter.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  selected: PropTypes.array,
  pageTab: PropTypes.string
}

export default SizeFilter
