import React, { useContext, useState, useRef, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import AbortController from 'abort-controller'
import get from 'lodash/get'
import { Box, Checkbox, Typography } from '@mui/material'

import PageLayout from '../containers/PageLayout'
import GlobalStore from '../../utils/store'
import { SearchBar } from '../common'
import { CrawlerSampleRow } from './buildComponents'
import {
  PageContext,
  SampleCrawlerProvider,
  fetchApi,
  parseObjToQueryParams,
  splitAllowedBlockedString
} from '../../utils/helpers'
import { PAGES, PREDICTORS, POST, GET, SAMPLES_DOMAINS } from '../../constants'
import LoadingCrawlerSampleRow from './LoadingCrawlerSampleRow'

const SamplesCrawler = () => {
  const {
    setPage,
    setActionBarProperties,
    filtersState: { appliedFilters, samplesLanguage },
    setFiltersProperties,
    handleErrorMsg
  } = useContext(PageContext)
  const { language } = get(GlobalStore.getLocal(), PAGES.PREDICTOR_BUILD, null) || {}
  // const [noOfSelected, setNoOfSelected] = useState(0)
  const [search, setSearch] = useState('')
  const [hasMore, setHasMore] = useState(false)
  const [disableSelectAll, setDisableSelectAll] = useState(false)
  const [domainsNo, setDomainsNo] = useState(0)
  const [domains, setDomains] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [expanded, setExpanded] = useState(null)
  const [controller, setController] = useState(null)
  const [selected, setSelected] = useState(new Set())
  const [unselected, setUnselected] = useState(new Set())
  const queryRef = useRef({})
  const userInteractionsRef = useRef([])
  let mounted = useRef(null)

  useEffect(() => {
    if (appliedFilters?.langs?.length) {
      setIsLoading(true)
      fetchDomains(1)
    }

    setDisableSelectAll(!!(search
      || appliedFilters?.users?.length
      || appliedFilters?.langs?.length > 1
      || appliedFilters?.date
      || appliedFilters?.sizes?.length))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    appliedFilters?.users,
    appliedFilters?.langs,
    appliedFilters?.date,
    appliedFilters?.sizes
  ])

  useEffect(() => {
    if (!mounted?.current) {
      const predictorData = get(GlobalStore.getLocal(), PAGES.PREDICTOR_BUILD, null) || {}
      setActionBarProperties({
        showBar: true,
        onCancel: () => {
          GlobalStore.setData(null, PAGES.PREDICTOR_BUILD)
          setPage(PAGES.MAIN)
          setActionBarProperties({ showBar: false })
          setFiltersProperties({ samples: false, samplesLanguage: null, appliedFilters: {} })
        },
        onAction,
        cancelLabel: 'Cancel',
        actionLabel: 'Build Predictor',
        // infoText: `${noOfSelected} Samples Selected`
      })
      setFiltersProperties({ samples: true,
        samplesLanguage: get(predictorData, 'language'),
        appliedFilters: {
          ...(appliedFilters || {}),
          langs: [get(predictorData, 'language')]
      }})
      mounted.current = true
    } else {
      setActionBarProperties({
        // infoText: `${noOfSelected} Samples Selected`,
        disableAction: false
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected?.size])

  const fetchDomains = async (page) => {
    const loadMore = page > 1
    let signal = null
    if (!loadMore) {
      const ctrl = new AbortController()
      setController(ctrl)
      signal = ctrl.signal
    }

    const newParams = {
      page: page || 1,
      search,
      ...appliedFilters,
      langs: [...(appliedFilters?.langs || [])].map(el => el === samplesLanguage ? `_${el}` : el)
    }
    queryRef.current = newParams
    const result = await fetchApi(`${SAMPLES_DOMAINS}${parseObjToQueryParams(newParams)}`, GET, null, {}, signal)
    if (!result?.error) {
      setDomains(loadMore ? [...(domains || []), ...(result?.results || [])] : result?.results)
      setHasMore(!!result?.next)
      setDomainsNo(result?.count)
      setIsLoading(false)
    }
  }

  const onAction = async () => {
    const predictorData = get(GlobalStore.getLocal(), PAGES.PREDICTOR_BUILD, null) || {}
    const body = {
      name: get(predictorData, 'name'),
      description: get(predictorData, 'description'),
      language: get(predictorData, 'language'),
      lexicon: get(predictorData, 'lexicon') === 'yes',
      loss: get(predictorData, 'loss') === 'yes',
      allowed: splitAllowedBlockedString(get(predictorData, 'allowed', '')),
      blocked: splitAllowedBlockedString(get(predictorData, 'blocked', '')),
      abbr_exp: get(predictorData, 'abbreviatedExpansions', '')
        .filter(el => el?.abbreviation && el?.expansion)
        .map(el => ([el?.abbreviation, el?.expansion])),
      selection: [
        ...(userInteractionsRef?.current || [])
      ]
    }

    const result = await fetchApi(PREDICTORS, POST, body)
    if (!result?.error) {
      GlobalStore.setData(null, PAGES.PREDICTOR_BUILD)
      setActionBarProperties({ showBar: false })
      setPage(PAGES.MAIN)
      setFiltersProperties({ samples: false, samplesLanguage: null, appliedFilters: {} })
    } else {
      handleErrorMsg(result?.error)
    }
  }

  const onSearch = (value) => {
    if (controller) {
      controller.abort()
      setController(null)
    }
    setSearch(value)
  }

  const handleChange = (e) => {
    const { value, name, checked } = e?.target || e

    if (name === 'expanded') {
      setExpanded(value === expanded ? null : value)
    }

    if (name === 'selectAll') {
      setSelectAll(checked)
      setUnselected(new Set())
      setSelected(new Set())
      // setNoOfSelected(checked ? domainsNo : 0)
      handleUserInteractions({
        mode: 'all',
        checked,
        query: parseObjToQueryParams(queryRef?.current || {}),
        item: ''
      })
    }
  }

  const handleItemsChange = (item) => {
    if (selectAll) {
      const newUnselected = new Set(unselected)
      if (newUnselected.has(item)) {
        newUnselected.delete(item)
      } else {
        newUnselected.add(item)
      }
      setUnselected(newUnselected)
      setSelected(new Set())
    } else {
      const newSelected = new Set(selected)
      if (newSelected.has(item)) {
        newSelected.delete(item)
      } else {
        newSelected.add(item)
      }
      setSelected(newSelected)
      setUnselected(new Set())
    }
  }

  const handleUserInteractions = (userInteraction) => {
    userInteractionsRef.current = [...(userInteractionsRef.current || []), userInteraction]
  }

  // const handleNoOfSelected = (no) => {
  //   setNoOfSelected(noOfSelected + no)
  // }

  return (
    <PageLayout title={`Select Samples for ${language} Predictor`}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <SearchBar
          onSearch={onSearch}
          resultsNo={domainsNo}
          searchSx={{ width: '100%' }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', px: 2, pt: 0.75 }}>
          <Box sx={{
            display: 'flex',
            borderBottom: '1px solid',
            borderColor: 'grey.400',
            alignItems: 'center'
          }}>
            <Checkbox
              disabled={disableSelectAll}
              name='selectAll'
              color='secondary'
              sx={{ ml: -1 }}
              checked={selectAll}
              onChange={handleChange}
            />
            <Typography component='p' variant='subtitle2'>
              Select All
            </Typography>
          </Box>
          <SampleCrawlerProvider
            value={{
              selectAll,
              expanded,
              selected,
              unselected,
              onChange: handleChange,
              onItemsChange: handleItemsChange,
              handleUserInteractions,
              // handleNoOfSelected,
              userInteractions: userInteractionsRef?.current
            }}
          >
            {isLoading ? (<LoadingCrawlerSampleRow />) : (
              <InfiniteScroll
                pageStart={1}
                style={{ width: '100%' }}
                loadMore={fetchDomains}
                hasMore={hasMore}
                loader={<LoadingCrawlerSampleRow key={`loading-crawler-sample-row`}/>}
              >
                {
                  domains.map((item, idx) => (
                    <CrawlerSampleRow
                      key={`crawler-sample-row-${idx}`}
                      domain={item?.domain}
                      selected={selectAll
                        ? !unselected?.has(item.domain)
                        : selected?.has(item?.domain)
                      }
                      count={item?.count}
                      sampleParams={{
                        page: get(queryRef, 'current.page', 1),
                        search,
                        ...appliedFilters
                      }}
                    />
                  ))
                }
              </InfiniteScroll>
            )}
          </SampleCrawlerProvider>
        </Box>
      </Box>
    </PageLayout>
  )
}

export default SamplesCrawler