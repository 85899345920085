import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material'

const defaultFormatter = (entry) => entry.label || entry.name || 'N/A'

const GenericRadioGroup = (props) => {
  const {
    onChange,
    items,
    name,
    defaultValue,
    value,
    formatter,
    ...otherProps
  } = props

  const handleChange = (e) => {
    const { value: val } = e?.target || e
    onChange(name, val)
  }
  return (
    <FormControl component='fieldset'>
      <RadioGroup
        name={name}
        value={value}
        onChange={handleChange}
        defaultValue={defaultValue}
      >
        {get(props, 'items', []).map((el, idx) => {
          const val = get(el, 'value')

          return (
            <FormControlLabel
              key={`radio-item-${idx}`}
              componentsProps={{ typography: { variant: 'subtitle1' } }}
              value={val}
              control={
                <Radio {...otherProps} />
              }
              label={formatter(el)}
              {...otherProps}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}

GenericRadioGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  formatter: PropTypes.func,
  items: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

GenericRadioGroup.defaultProps = {
  formatter: defaultFormatter,
  value: '',
  defaultValue: '',
}

export default GenericRadioGroup
