import React, { useState, useContext, useEffect } from 'react'
import get from 'lodash/get'
import { Grid } from '@mui/material'

import GlobalStore from '../../utils/store'
import GenericDrawer from './GenericDrawer'
import GenericAccordion from './GenericAccordion'
import DateFilter from './filters/DateFilter'
import UsersFilter from './filters/UsersFilter'
import SizeFilter from './filters/SizeFilter'
import LanguageFilter from './filters/LanguageFilter'
import { PageContext, fetchApi } from '../../utils/helpers'
import { LANGUAGE_OPTIONS, SAMPLES_USERS, PREDICTORS_USERS, MAIN_TABS } from '../../constants'

const filterStyle = { '&.MuiGrid-item': { pl: 0 } }

const Filters = () => {
  const languages = get(GlobalStore.getLocal(), LANGUAGE_OPTIONS, []) || []
  const { filtersState, setFiltersProperties, pageTab } = useContext(PageContext)
  const { showFilters, samples, samplesLanguage, appliedFilters } = filtersState || {}
  const [state, setState] = useState({...appliedFilters})
  const [expanded, setExpanded] = useState([])
  const [users, setUsers] = useState([])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTab, samples])

  useEffect(() => {
    setState({...appliedFilters})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersState])

  const fetchData = async () => {
    const usersRes = await fetchApi((pageTab === MAIN_TABS.SAMPLES || samples)
      ? SAMPLES_USERS
      : PREDICTORS_USERS)
    if (!usersRes?.error) {
      const usersOptions = usersRes?.map(el => ({
        value: `${el?.id}`,
        label: el?.first_name && el?.last_name ? `${el?.first_name} ${el?.last_name}` : el?.username
      }))

      setUsers(usersOptions)
    }
  }

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value
    })
  }

  const handleAccordionChange = (e) => {
    const { value } = e?.target || e
    const valueIdx = expanded?.findIndex(el => el === value)
    const newValue = valueIdx > -1
      ? [
        ...expanded.slice(0, valueIdx),
        ...expanded.slice(valueIdx + 1, expanded.length)
      ]
      : [...expanded, value]

    setExpanded(newValue)
  }

  const handleClose = () => {
    setFiltersProperties({ showFilters: false })
    setState(null)
    setExpanded([])
  }

  const handleClear = () => {
    setFiltersProperties({ showFilters: false, appliedFilters: {
      ...(samples ?
        { langs: [samplesLanguage] }
        : {})
    }})
    setState(null)
    setExpanded([])
  }

  const handleSubmit = () => {
    setFiltersProperties({ appliedFilters: state, showFilters: false })
  }

  const DateFilterComp = () => (<DateFilter
    name='date'
    onChange={handleChange}
    value={state?.date}
  />)

  const UsersFilterComp = () => (<UsersFilter
    name='users'
    onChange={handleChange}
    options={users}
    selected={state?.users}
  />)

  const SizeFilterComp = () => (<SizeFilter
    name='sizes'
    onChange={handleChange}
    selected={state?.sizes}
    pageTab={pageTab}
  />)

  const LanguageFilterComp = () => (<LanguageFilter
    disabledDefault={!!samples ? [samplesLanguage] : []}
    name='langs'
    options={languages}
    onChange={handleChange}
    selected={state?.langs}
  />)

  return showFilters ? (
    <GenericDrawer
      open={showFilters}
      onClose={handleClose}
      title='Filters'
      cancelLabel='Clear'
      submitLabel='Save'
      onSubmit={handleSubmit}
      onCancel={handleClear}
    >
      <Grid container spacing={1.5} sx={{ width: '100%', ml: 0 }}>
        <Grid item xs={12} sx={filterStyle}>
          <GenericAccordion
            title='Date'
            value='date'
            expanded={expanded}
            DetailsComp={DateFilterComp}
            onChange={handleAccordionChange}
          />
        </Grid>
        <Grid item xs={12} sx={filterStyle}>
          <GenericAccordion
            title='Users'
            value='users'
            expanded={expanded}
            DetailsComp={UsersFilterComp}
            onChange={handleAccordionChange}
          />
        </Grid>
        <Grid item xs={12} sx={filterStyle}>
          <GenericAccordion
            title='Size'
            value='size'
            expanded={expanded}
            DetailsComp={SizeFilterComp}
            onChange={handleAccordionChange}
          />
        </Grid>
        <Grid item xs={12} sx={filterStyle}>
          <GenericAccordion
            title='Language'
            value='language'
            expanded={expanded}
            DetailsComp={LanguageFilterComp}
            onChange={handleAccordionChange}
          />
        </Grid>
      </Grid>
    </GenericDrawer>
  ) : null
}

export default Filters