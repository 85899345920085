import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import DownloadIcon from '@mui/icons-material/Download'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Typography, Box, Button } from '@mui/material'

import { download } from '../../../utils/helpers'

const ViewTitle = (props) => {
  const { sampled, annotation, content, id } = props
  const displayDate = dayjs(sampled)

  const handleCopy = async () => {
    if (navigator) {
      await navigator.clipboard.writeText(content)
    }
  }

  const handleDownload = () => {
    download(id, content)
  }

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center'
    }}>
      <Typography component='p' variant='h1' sx={{ mb: 1 }}>Sample</Typography>
      <Typography component='p' variant='subtitle1' sx={{ color: 'grey.300', mb: 1 }}>
        {displayDate.isValid()
          ? displayDate.locale('en')
            .format('MMMM D, YYYY ; HH:mm a')
            .replace(';', 'at')
          : '-'}
      </Typography>
      {!!annotation && (
        <Typography component='p' variant='subtitle1' sx={{ color: 'text.primary' }}>
          {annotation}
        </Typography>
      )}
      <Box sx={{ mt: 2 }}>
        <Button
          variant='outlined'
          sx={{ mr: 2 }}
          startIcon={(
            <DownloadIcon
              fontSize='small'
              sx={{ color: 'secondary.main', mr: 0.3 }}/>
          )}
          onClick={handleDownload}
        >
          Download
        </Button>
        <Button
          variant='outlined'
          startIcon={(
            <ContentCopyIcon
              fontSize='small'
              sx={{ color: 'secondary.main', mr: 0.3, transform: 'rotateY(180deg)' }}/>
          )}
          onClick={handleCopy}
        >
          Copy Text
        </Button>
      </Box>
    </Box>
  )
}

ViewTitle.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sampled: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  annotation: PropTypes.string,
  content: PropTypes.string
}

export default ViewTitle
