import React, { useContext, useState, useEffect } from 'react'
import get from 'lodash/get'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Box, IconButton, Grid } from '@mui/material'

import GlobalStore from '../../utils/store'
import { ViewHeader, PreviewCard } from './viewComponents'
import { GenericDialog } from '../common'
import { PageContext, fetchApi } from '../../utils/helpers'
import {
  CANCEL_LABEL,
  DELETE_FOREVER_LABEL,
  DELETE_PREDICTOR_TEXT,
  PAGES,
  PREDICTORS,
  DELETE
} from '../../constants'

const sxContainer = { mb: 3, mr: 3 }

/**
 * Note: Although the languages field is a list, predictor objects are associated with
 * exactly one language code. A list is used to allow for the possibility of producing
 * multi-language predictors in the future.
 **/
const ViewPredictor = () => {
  const { setPage, handleErrorMsg } = useContext(PageContext)
  const [deletePredictor, setDeletePredictor] = useState(false)
  const [duplicatePredictor, setDuplicatePredictor] = useState(null)
  const [predictor, setPredictor] = useState(false)
  const [detailsContent, setDetailsContent] = useState([])
  const [abbreviatedExpansionContent, setAbbreviatedExpansionContent] = useState([])
  const { id } = get(GlobalStore.getLocal(), PAGES.PREDICTOR_VIEW)

  useEffect(() => {
    fetchPredictor()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicatePredictor])

  const fetchPredictor = async () => {
    const result = await fetchApi(`${PREDICTORS}${duplicatePredictor || id}/`)
    if (!result?.error) {
      setPredictor(result)
      setAbbreviatedExpansionContent(result?.abbr_exp?.map(el => ({
        name: el[0],
        value: el[1]
      })))
      setDetailsContent([
        {
          name: 'WPPL version',
          value: result?.wppl_version
        },
        {
          name: 'Lexicon version',
          value: result?.lexicon_version
        },
        {
          name: 'Target language',
          value: result?.languages?.length ? result?.languages[0] : 'noLanguage'
        },
        {
          name: 'Loss applied',
          value: result?.loss ? 'Yes' : 'No'
        },
        {
          name: 'Full lexicon included',
          value: result?.lexicon ? 'Yes' : 'No'
        }
      ])
    } else {
      handleErrorMsg(result?.error)
    }
  }

  const handleBack = () => {
    GlobalStore.setData(null, PAGES.PREDICTOR_VIEW)
    setPage(PAGES.MAIN)
  }

  const handleDeletePredictor = () => setDeletePredictor(!deletePredictor)

  const onDelete = async () => {
    const result = await fetchApi(`${PREDICTORS}${id}/`, DELETE)
    if (!result?.error) {
      setPage(PAGES.MAIN)
      setDeletePredictor(false)
    } else {
      handleErrorMsg(result?.error)
    }
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center'
        }}
      >
        <IconButton onClick={handleBack} sx={{ width: 25, height: 25 }}>
          <ArrowRightAltIcon sx={{ color: 'grey.300', transform: 'rotate(180deg)' }}/>
        </IconButton>
        <ViewHeader
          {...(predictor || {})}
          handleDuplicate={setDuplicatePredictor}
          handleDelete={handleDeletePredictor}
        />
        <Grid container spacing={3} sx={{ m: 0, p: 0, width: '100%' }}>
          <Grid item xs={6} sx={{ '&.MuiGrid-item': { pl: 0 } }}>
            <PreviewCard
              title='Description'
              content={predictor?.description}
              placeholder='No description provided'
              sxContainer={sxContainer}
            />
            <PreviewCard
              title='Allowed'
              content={predictor?.allowed?.join(' ')}
              placeholder='No words added yet'
              sxContainer={sxContainer}
            />
            <PreviewCard
              title='Blocked'
              content={predictor?.blocked?.join(' ')}
              placeholder='No words added yet'
              sxContainer={sxContainer}
            />
            <PreviewCard
              title='Abbreviated Expansion'
              content={abbreviatedExpansionContent}
              placeholder='No abbreviated expansion added yet'
              sxContainer={sxContainer}
            />
          </Grid>
          <Grid item xs={6}>
            <PreviewCard
              title='Details'
              content={detailsContent}
              placeholder='No details provided'
            />
          </Grid>
        </Grid>
      </Box>
      <GenericDialog
        open={deletePredictor}
        onClose={handleDeletePredictor}
        title='Delete Predictor?'
        text={DELETE_PREDICTOR_TEXT(get(predictor, 'name', '-'))}
        cancelLabel={CANCEL_LABEL}
        submitLabel={DELETE_FOREVER_LABEL}
        onCancel={handleDeletePredictor}
        onSubmit={onDelete}
      />
    </>
  )
}

export default ViewPredictor