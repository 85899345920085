import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import keys from 'lodash/keys'

import { UserProvider, fetchApi, emptyFn } from '../../utils/helpers'
import { USER, LOGOUT } from '../../constants'
import { LoginContent } from '../login'
import { GenericDialog } from '../common'

const WithUser = (props) => {
  const { children } = props
  const [user, setUser] = useState(null)
  const [open, setOpen] = useState(true)
  const [loading, setLoading] = useState(false)

  const getUser = async () => {
    let initialUser = '{}'
    let token = null
    if (window) {
      initialUser = window?.sessionStorage?.getItem('user')
      token = window?.sessionStorage?.getItem('token')
    }

    initialUser = initialUser !== 'null' && initialUser !== 'undefined' && JSON.parse(initialUser)

    if (!initialUser && !keys(initialUser)?.length && token) {
      setLoading(true)
      const result = await fetchApi(USER)
      initialUser = result
    }

    setUser(initialUser)
    setLoading(false)
    setOpen(!initialUser || initialUser?.error || initialUser === 'null')
    if (!initialUser?.error && initialUser !== 'null') {
      window?.sessionStorage?.setItem('user', JSON.stringify(initialUser, null, 2))
    }
  }

  const setToken = () => {
    const params = new URLSearchParams(document?.location?.search)
    const token = params.get('token')

    if (window && token) {
      window?.sessionStorage?.setItem('token', token)
      window.history.pushState('', '', window?.location?.origin)
    }
  }

  useEffect(() => {
    setToken()
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const doLogout = async () => {
    setUser(null)
    setOpen(true)
    const result = await fetchApi(LOGOUT)
    if (window && !result?.error) {
      window.sessionStorage.removeItem('user')
      window.sessionStorage.removeItem('token')
    }
  }

  const LoginDialogContent = () => <LoginContent error={user?.error} loading={loading} />

  return (
    <>
      <GenericDialog
        smallWidth
        blurBackground
        open={open}
        onClose={emptyFn}
        Comp={LoginDialogContent}
      />
      <UserProvider
        value={{
          doLogout,
          getUser,
          user,
          loading
        }}
      >
        {user ? children : null}
      </UserProvider>
    </>
  )
}

WithUser.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WithUser