import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Avatar, Typography } from '@mui/material'
import { isLightColor } from '../../utils/helpers'

const GenericAvatar = (props) => {
  const { initials, src, sxAvatar, id } = props
  const background = [
    'grey.200',
    'common.white',
    'accent.100',
    'accent.200',
    'primary.main',
    'background.default',
    'accent.50',
    'text.secondary',
    'text.disabled',
    'grey.100'
  ]
  const userColorIdx = id % background.length
  const userColor = background[userColorIdx] || 'common.white'

  return src ? (
    <Avatar
      alt={initials}
      src={src}
      sx={{ width: 32, height: 32, mr: 2, ...(sxAvatar || {}) }}
    />
  ) : (
    <Avatar
      alt={initials}
      sx={theme => ({
        width: 32,
        height: 32,
        mr: 2,
        backgroundColor: get(theme.palette, userColor),
        ...(userColor === 'common.white' || userColor === 'background.default'
          ? { border: '1px solid', borderColor: 'grey.400' } : {}),
        ...(sxAvatar || {})
      })}
    >
      <Typography
        component='p'
        variant='body2'
        sx={theme => ({
          color: isLightColor(get(theme.palette, userColor))
            ? 'secondary.main' : 'common.white'
        })}
      >
        {initials?.toLocaleUpperCase()}
      </Typography>
    </Avatar>
  )
}

GenericAvatar.propTypes = {
  src: PropTypes.string,
  id: PropTypes.number,
  initials: PropTypes.string,
  sxAvatar: PropTypes.object
}

export default GenericAvatar
