import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

const PreviewCardRow = (props) => {
  const { name, value } = props

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 1
      }}
    >
      <Typography
        component='p'
        variant='subtitle1'
      >
        {name || '-'}
      </Typography>
      <Typography
        component='p'
        variant='subtitle1'
      >
        {value || '-'}
      </Typography>
    </Box>
  )
}

PreviewCardRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string
}

export default PreviewCardRow