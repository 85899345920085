import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import { alpha } from '@mui/material/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton
} from '@mui/material'

const PreviewDialog = (props) => {
  const { open, onClose, title, TitleComp, ContentComp, contentString, sxContentString } = props
  const [isScrollVisible, setIsScrollVisible] = useState(false)

  const handleRect = useCallback((node) => {
    const { scrollHeight, clientHeight } = node || {}
    setIsScrollVisible(scrollHeight > clientHeight)
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='paper'
      PaperProps={{
        className: 'preview-dialog'
      }}
      sx={theme => ({
        '& .MuiDialogContent-root': {
          color: 'text.primary'
        },
        '& .MuiDialog-container': {
           backgroundColor: alpha(theme.palette.grey[300], 0.3)
        }
      })}
      transitionDuration={1}
    >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 21,
            top: 21
          }}
        >
          <CloseIcon fontSize='medium' sx={{ color: 'text.primary' }} />
        </IconButton>
        <DialogTitle
          sx={theme => ({
            borderBottom: `1px solid ${theme.palette.grey[400]}`,
            pb: 2.37,
            mb: 3,
            mr: 5,
            [theme.breakpoints.down('md')]: {
              mr: 17
            }
          })}
        >
          {TitleComp
            ? (<TitleComp />)
            : (<Typography component='p' variant='h1'>{title}</Typography>)}
        </DialogTitle>
        <DialogContent
          ref={handleRect}
          sx={theme => ({
            p: theme.spacing(0, isScrollVisible ? 3 : 5, 0, 0),
            [theme.breakpoints.down('md')]: {
              p: theme.spacing(0, isScrollVisible ? 15 : 17, 0, 0),
            }
          })}>
          {ContentComp && (<ContentComp />)}
          <Typography
            component='p'
            variant='subtitle1'
            sx={sxContentString}
          >
            {contentString}
          </Typography>
        </DialogContent>
    </Dialog>
  )
}

PreviewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  TitleComp: PropTypes.elementType,
  ContentComp: PropTypes.elementType,
  contentString: PropTypes.string,
  sxContentString: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

PreviewDialog.defaultProps = {
  title: 'Preview Modal Title',
  sxContentString: {}
}

export default PreviewDialog
