import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import Header from '../common/Header'
import Footer from '../common/Footer'

const Layout = (props) => {
  const { children } = props

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: 'background.200',
        minHeight: '100vh'
      }}
    >
      <Header />
      <Box
        sx={theme => ({
          height: '100%',
          position: 'relative',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          padding: theme.spacing(4.33375, 2, 0, 2),
        })}
      >
        <Box
          component='div'
          sx={{
            mb: 4,
            width: '100%',
            maxWidth: 1140,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {children}
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout