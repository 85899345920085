import React from 'react'
import { Box, Skeleton } from '@mui/material'

const LoadingPredictorRow = () => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 80,
      mb: 1
    }}>
    <Skeleton
      animation='wave'
      variant='rectangular'
      height={64}
      width={64}
      sx={theme => ({
        borderRadius: '2px',
        background: `linear-gradient(90.44deg, ${theme.palette.grey[400]}
         -86.35%, ${theme.palette.common.white} 167.55%)`
      })}
    />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '45%',
        ml: '-14%'
      }}
    >
      <Skeleton
        animation='wave'
        variant='text'
        height={20}
        width='70%'
        sx={theme => ({
          borderRadius: 0,
          background: `linear-gradient(90.44deg, ${theme.palette.grey[400]}
         -86.35%, ${theme.palette.common.white} 167.55%)`
        })}
      />
      <Skeleton
        animation='wave'
        variant='text'
        height={20}
        width='100%'
        sx={theme => ({
          borderRadius: 0,
          background: `linear-gradient(90.44deg, ${theme.palette.grey[400]}
         -86.35%, ${theme.palette.common.white} 167.55%)`
        })}
      />
    </Box>
    <Skeleton
      animation='wave'
      variant='text'
      height={20}
      width='4%'
      sx={theme => ({
        borderRadius: 0,
        mr: '-14%',
        background: `linear-gradient(90.44deg, ${theme.palette.grey[400]}
         -86.35%, ${theme.palette.common.white} 167.55%)`
      })}
    />
    <Skeleton
      animation='wave'
      variant='text'
      height={20}
      width='8%'
      sx={theme => ({
        borderRadius: 0,
        mr: '-17%',
        background: `linear-gradient(90.44deg, ${theme.palette.grey[400]}
         -86.35%, ${theme.palette.common.white} 167.55%)`
      })}
    />
    <Skeleton
      animation='wave'
      variant='circular'
      height={31}
      width={31}
      sx={theme => ({
        background: `linear-gradient(90.44deg, ${theme.palette.grey[400]}
         -86.35%, ${theme.palette.common.white} 167.55%)`
      })}
    />
  </Box>
)

export default LoadingPredictorRow
