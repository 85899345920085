import React from 'react'
import PropTypes from 'prop-types'
import { Radio, Box, Typography } from '@mui/material'

const CrawlRadioButton = (props) => {
  const { name, value, title, subtitle, checked, onChange } = props

  const handleClick = () => {
    onChange({ value, name })
  }

  return (
    <Box
      component='button'
      onClick={handleClick}
      sx={theme => ({
        cursor: 'pointer',
        height: 80,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0.5,
        mb: 1.5,
        backgroundColor: checked ? theme.palette.background[300] : theme.palette.common.white,
        ...(!checked ? { border: `1px solid ${theme.palette.grey[400]}` } : { border: 0 })
      })}
    >
      <Radio
        checked={checked}
        value={value}
        sx={{
          '&.Mui-checked': {
            color: 'common.white'
          }
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left'
        }}
      >
        <Typography
          component='p'
          variant='subtitle2'
          sx={{ color: checked ? 'common.white' : 'secondary.main' }}
        >
          {title}
        </Typography>
        <Typography
          component='p'
          variant='subtitle1'
          sx={{ color: checked ? 'common.white' : 'secondary.main' }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  )
}

CrawlRadioButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onChange: PropTypes.func
}

export default CrawlRadioButton