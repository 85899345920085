import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

const PredictorConfigNameRow = (props) => {
  const { index, name } = props

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <Typography component='p' variant='subtitle2'>{`Predictor ${index}:`}</Typography>
      <Typography component='p' variant='subtitle1' sx={{ ml: 0.5 }}>{name}</Typography>
    </Box>
  )
}

PredictorConfigNameRow.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string
}

export default PredictorConfigNameRow