import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Typography } from '@mui/material'

import { AccordionCheckbox } from '../../common'

const CrawlerSampleRow = (props) => {
  const { domain, count, sampleParams, selected } = props

  const formatter = (label) => (
    <Tooltip title={label} placement='bottom-end'>
      <Typography
        component='p'
        variant='subtitle1'
        sx={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}
      >
        {label}
      </Typography>
    </Tooltip>
  )

  return (
    <AccordionCheckbox
      name='expanded'
      value={domain}
      label={domain}
      count={count}
      selectedDomain={selected}
      sampleParams={sampleParams}
      formatter={formatter}
      sxContainer={{ mr: -2, ml: -2, width: 'auto' }}
    />
  )
}

CrawlerSampleRow.propTypes = {
  domain: PropTypes.string,
  count: PropTypes.number,
  selected: PropTypes.bool,
  sampleParams: PropTypes.object
}

export default CrawlerSampleRow