import { alpha } from '@mui/material/styles'

import palette from '../gatsby-theme-material-ui-top-layout/palette'

export const customSecondaryBtn = {
  color: palette.common.white,
  backgroundColor: palette.grey[200],
  '&:hover': {
    backgroundColor: palette.grey[100],
  },
  '&:disabled': {
    backgroundColor: alpha(palette.grey[200], 0.6),
  }
}