import React  from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import NoteIcon from '@mui/icons-material/Note'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  Grid,
  Typography,
  Chip,
  Tooltip
} from '@mui/material'

import { MenuIcon, GenericAvatar } from '../common'
import { emptyFn, download, fetchApi } from '../../utils/helpers'
import { languagesTooltipContent } from '../../utils/crawlerHelpers'
import { SAMPLES } from '../../constants'

const options = [
  { label: 'View', value: 'view' },
  { label: 'Visit URL', value: 'visitUrl' },
  { label: 'Download', value: 'download' }
]

const sectionStyle = { display: 'flex', alignItems: 'center', justifyContent: 'end' }

const CrawlerRow = (props) => {
  const {
    url,
    languages,
    annotation,
    date,
    handleView,
    user_initials,
    image,
    id,
    user_id
  } = props
  const displayDate = dayjs(date)
  const language = languages?.length && languages[0] && languages[0].split('(')[0]

  const actions = {
    view: async () => {
      const result = await fetchApi(`${SAMPLES}${id}/`)
      if (!result?.error) {
        handleView(result)
      }
    },
    visitUrl: () => window && window.open(url, '_blank'),
    download: async () => {
      const result = await fetchApi(`${SAMPLES}${id}/`)
      if (!result?.error) {
        download(result?.id, result?.content)
      }
    }
  }

  return (
    <Grid
      container
      sx={theme => ({
        width: '100%',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        p: theme.spacing(0, 2),
        justifyContent: 'space-between',
        backgroundColor: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.background[100]
        }
      })}
    >
      <Grid item xs={4}>
        <Tooltip title={url} placement='bottom-end'>
          <Typography
            component='p'
            variant='subtitle1'
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
          >
            {url}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={3} sx={sectionStyle}>
        <Chip className='dark' label={language}/>
        <Tooltip
          placement='bottom-end'
          title={languagesTooltipContent(languages)}
        >
          <Chip
            label={`+${languages.length}`}
            sx={{ ml: 1.5, visibility: languages?.length > 1 ? 'visible' : 'hidden' }}
          />
        </Tooltip>
      </Grid>
      <Grid item sm={1} md={3} sx={sectionStyle}>
        {!!annotation && (
          <Tooltip title={annotation} placement='bottom-end'>
            <NoteIcon
              fontSize='small'
              sx={{ color: 'grey.300', cursor: 'pointer', mr: { sm: 0, md: 2 } }}/>
          </Tooltip>
        )}
      </Grid>
      <Grid item sm={4} md={2} sx={sectionStyle}>
        <Typography
          component='p'
          variant='subtitle1'
          sx={{ color: 'grey.300', mr: 2 }}
        >
          {displayDate.isValid() ? displayDate.locale('en').format('MMM D') : '-'}
        </Typography>
        <GenericAvatar
          initials={user_initials || ''}
          src={image || ''}
          id={user_id}
        />
        <MenuIcon
          sx={{
              width: 32,
              height: 32,
              borderRadius: 0.5,
              '&:hover': {
                border: '1px solid',
                borderColor: 'grey.300',
                backgroundColor: 'transparent'
              }
          }}
          actions={actions}
          options={options}
        >
          <MoreHorizIcon
            fontSize='small'
            sx={{ color: 'grey.300' }}/>
        </MenuIcon>
      </Grid>
    </Grid>
  )
}

CrawlerRow.propTypes = {
  url: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.string),
  annotation: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleView: PropTypes.func,
  user_initials: PropTypes.string,
  user_id: PropTypes.number,
  id: PropTypes.number,
  image: PropTypes.string
}

CrawlerRow.defaultProps = {
  languages: [],
  handleView: emptyFn
}

export default CrawlerRow
