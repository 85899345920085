import React, { useState, useEffect, useContext, useRef } from 'react'
import get from 'lodash/get'
import { Grid, InputLabel, OutlinedInput } from '@mui/material'

import GlobalStore from '../../utils/store'
import PageLayout from '../containers/PageLayout'
import { GenericTextArea, GenericSelect, GenericAccordion } from '../common'
import { AdvancedOptions } from './buildComponents'
import { PAGES, PREDICTORS, PATCH, POST, LANGUAGE_OPTIONS, PREDICTOR_COPY } from '../../constants'
import { lexiconData, lossData } from '../../data'
import {
  fetchApi,
  PageContext,
  PredictorOptionsProvider,
  splitAllowedBlockedString
} from '../../utils/helpers'

const rowStyle = { textAlign: 'left' }

const BuildPredictor = () => {
  const {
    setPage,
    setActionBarProperties,
    actionBarState: { disableAction },
    handleErrorMsg
  } = useContext(PageContext)
  const { id, view } = get(GlobalStore.getLocal(), PAGES.PREDICTOR_EDIT, null) || {}
  const languages = get(GlobalStore.getLocal(), LANGUAGE_OPTIONS, []) || []
  const [state, setState] = useState({
    name: '',
    description: '',
    language: null,
    advancedOptions: null,
    lexicon: lexiconData[0]?.value,
    loss: lossData[0]?.value,
    allowed: '',
    blocked: '',
    abbreviatedExpansions: []
  })
  const stateRef = useRef(state)
  const predictorId = useRef(id)
  const fromViewPage = useRef(view)
  let mounted = useRef(null)

  useEffect(() => {
    if (id) {
      fetchPredictor()
      predictorId.current = id
    }

    fromViewPage.current = view
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, view])

  useEffect(() => {
    if (!mounted?.current) {
      setActionBarProperties({
        showBar: true,
        onCancel: () => {
          if(fromViewPage?.current) {
            GlobalStore.setData({ id: predictorId?.current }, PAGES.PREDICTOR_VIEW)
          }
          GlobalStore.setData(null, PAGES.PREDICTOR_EDIT)
          setPage(fromViewPage?.current ? PAGES.PREDICTOR_VIEW : PAGES.MAIN)
          setActionBarProperties({ showBar: false })
        },
        onAction,
        cancelLabel: 'Cancel',
        actionLabel: id ? 'Save Changes' : 'Continue to Samples',
        disableAction: !(state?.name && state?.language),
        ...(id
          ? { optionalLabel: 'Save as New', onOptionalAction }
          : {})
      })
      mounted.current = true
    } else {
      const disableActionState = !(state?.name && state?.language)

      if (disableActionState !== disableAction) {
        setActionBarProperties({ disableAction: disableActionState })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.name, state?.language])

  const fetchPredictor = async () => {
    const result = await fetchApi(`${PREDICTORS}${id}/`)
    const abbr_expr = get(result, 'abbr_exp', []).map(el => ({
      abbreviation: el[0],
      expansion: el[1]
    }))
    const loss = result?.loss ? lossData[0]?.value : lossData[1]?.value
    const lexicon = result?.lexicon ? lexiconData[0]?.value : lexiconData[1]?.value
    if (!result?.error) {
      setState({
        name: get(result, 'name', ''),
        description: get(result, 'description', ''),
        language: get(result, 'languages', [])[0],
        lexicon,
        loss,
        allowed: get(result, 'allowed', []).join(', '),
        blocked: get(result, 'blocked', []).join(', '),
        abbreviatedExpansions: abbr_expr?.length > 0 ? abbr_expr : []
      })
    } else {
      handleErrorMsg(result?.error)
    }
  }

  const onAction = async () => {
    if (predictorId?.current) {
      const body = {
        name: get(stateRef, 'current.name', ''),
        description: stateRef?.current?.description,
        allowed: splitAllowedBlockedString(get(stateRef, 'current.allowed', '')),
        blocked: splitAllowedBlockedString(get(stateRef, 'current.blocked', '')),
        abbr_exp: get(stateRef, 'current.abbreviatedExpansions')
          .filter(el => el?.abbreviation && el?.expansion)
          .map(el => ([el?.abbreviation, el?.expansion]))
      }
      const result = await fetchApi(`${PREDICTORS}${id}/`, PATCH, body)
      if (!result?.error) {
        if(fromViewPage?.current) {
          GlobalStore.setData({ id: predictorId?.current }, PAGES.PREDICTOR_VIEW)
        }
        GlobalStore.setData(null, PAGES.PREDICTOR_EDIT)
        setPage(fromViewPage?.current ? PAGES.PREDICTOR_VIEW : PAGES.MAIN)
        setActionBarProperties({ showBar: false })
      } else {
        handleErrorMsg(result?.error)
      }
    } else {
      GlobalStore.setData(stateRef?.current, PAGES.PREDICTOR_BUILD)
      setPage(PAGES.CRAWLERS_SAMPLE)
    }
  }

  const onOptionalAction = async () => {
    const result = await fetchApi(PREDICTOR_COPY(predictorId?.current), POST, {
      name: get(stateRef, 'current.name', ''),
      description: stateRef?.current?.description,
      allowed: splitAllowedBlockedString(get(stateRef, 'current.allowed', '')),
      blocked: splitAllowedBlockedString(get(stateRef, 'current.blocked', '')),
      abbr_exp: get(stateRef, 'current.abbreviatedExpansions')
        .filter(el => el?.abbreviation && el?.expansion)
        .map(el => ([el?.abbreviation, el?.expansion]))
    })

    if (!result?.error) {
      if(fromViewPage?.current) {
        GlobalStore.setData({ id: predictorId?.current }, PAGES.PREDICTOR_VIEW)
      }
      GlobalStore.setData(null, PAGES.PREDICTOR_EDIT)
      setPage(fromViewPage?.current ? PAGES.PREDICTOR_VIEW : PAGES.MAIN)
      setActionBarProperties({ showBar: false })
    } else {
      handleErrorMsg(result?.error)
    }
  }

  const handleChange = (e) => {
    const { value, name } = e?.target || e
    const newState = {
      ...state,
      [name]: (name === 'advancedOptions') && (value === state?.advancedOptions) ? null : value
    }

    setState(newState)
    stateRef.current = newState
  }

  return (
    <PageLayout title={id ? 'Edit Predictor' : 'Build Predictor'}>
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12} sx={rowStyle}>
          <InputLabel required htmlFor='name'>Name</InputLabel>
          <OutlinedInput
            name='name'
            placeholder='Type name...'
            value={state?.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sx={rowStyle}>
          <InputLabel htmlFor='description'>Description</InputLabel>
          <GenericTextArea
            name='description'
            placeholder='Type description...'
            value={state?.description}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sx={rowStyle}>
          <InputLabel required={!id} htmlFor='language'>Language</InputLabel>
          <GenericSelect
            name='language'
            disabled={!!id}
            options={languages}
            onChange={handleChange}
            value={state?.language}
            label={state?.language
              ? languages.find(el => el?.value === state?.language)?.label
              : 'Select language...'
            }
            sxButton={{ width: '100%', justifyContent: 'space-between' }}
            sxMenu={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sx={rowStyle}>
          <PredictorOptionsProvider
            value={{
              id,
              lexicon: state?.lexicon,
              loss: state?.loss,
              allowed: state?.allowed,
              blocked: state?.blocked,
              abbreviatedExpansions: state?.abbreviatedExpansions,
              onChange: handleChange,
            }}
          >
            <GenericAccordion
              name='advancedOptions'
              title='Advanced Options'
              value='advancedOptions'
              detailsSx={{ p: 0 }}
              expanded={state?.advancedOptions}
              DetailsComp={AdvancedOptions}
              onChange={handleChange}
            />
          </PredictorOptionsProvider>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default BuildPredictor