import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Typography,
  IconButton
} from '@mui/material'

import GenericAvatar from '../GenericAvatar'
import { emptyFn } from '../../../utils/helpers'
import { CRAWL, PREDICTOR } from '../../../constants'

const statuses = {
  started: 'background.300',
  pending: 'common.white'
}

const TaskListElement = forwardRef((props, ref) => {
  const {
    status,
    id,
    method,
    onRemove,
    image,
    user_initials,
    user_id,
    label,
    ...otherProps
  } = props
  const isWaiting = status === 'pending'

  const handleRemove = () => {
    console.log('handleRemove', id)
    onRemove(id)
  }

  return (
    <Box
      ref={ref}
      {...(otherProps || {})}
      component='div'
      sx={theme => ({
        height: 70, // 64,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        p: theme.spacing(1, 2.5),
        mt: 1,
        color: theme.palette.common.white,
        backgroundColor: statuses[status],
        ...(isWaiting ? {
          border: `1px solid ${theme.palette.grey[400]}`,
          color: theme.palette.grey[300]
        } : {})
      })}
    >
      <Box
        component='div'
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {isWaiting
          ? <DragIndicatorIcon fontSize='medium'/>
          : <AutorenewIcon fontSize='medium'/>
        }
        <Box
          component='div'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: 2
          }}
        >
          <Typography
            component='p'
            variant='subtitle2'
            sx={{
              color: isWaiting ? 'grey.50' : 'common.white',
              mt: 1,
              mr: 1.5,
              wordBreak: 'break-word',
            }}
          >
            {label || id}
          </Typography>
          <Typography
            component='p'
            variant='subtitle1'
            sx={{
              color: isWaiting ? 'grey.300' : 'common.white',
              textTransform: 'capitalize',
              mb: 1
            }}
          >
            {method === CRAWL ? CRAWL : PREDICTOR}
          </Typography>
        </Box>
      </Box>
      <Box
        component='div'
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <GenericAvatar
          src={image}
          initials={user_initials}
          id={user_id}
        />
        <IconButton onClick={handleRemove} sx={{p: 0}}>
          <CloseIcon
            fontSize='small'
            sx={{ color: isWaiting ? 'grey.300' : 'common.white' }}/>
        </IconButton>
      </Box>
    </Box>
  )
})

TaskListElement.propTypes = {
  status: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  method: PropTypes.string,
  user_initials: PropTypes.string,
  user_id: PropTypes.number,
  image: PropTypes.string,
  label: PropTypes.string,
  onRemove: PropTypes.func,
}

TaskListElement.defaultProps = {
  status: 'pending',
  id: 'Task',
  method: 'Crawler',
  image: '',
  onRemove: emptyFn
}

TaskListElement.displayName = 'TaskListElement'

export default TaskListElement
