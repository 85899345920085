import React from 'react'
import PropTypes from 'prop-types'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Box, Typography, IconButton } from '@mui/material'

import { emptyFn } from '../../utils/helpers'

const PageLayout = (props) => {
  const { title, subtitle, backButton, onBack, children } = props

  return (
    <Box
      sx={theme => ({
        width: '100%',
        maxWidth: 558,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        [theme.breakpoints.down('lg')]: {
          maxWidth: 484
        },
        [theme.breakpoints.down('md')]: {
          maxWidth: 482
        }
      })}
    >
      {backButton ? (
        <IconButton onClick={onBack} sx={{ width: 25, height: 25 }}>
          <ArrowRightAltIcon sx={{ color: 'grey.300', transform: 'rotate(180deg)' }}/>
        </IconButton>
      ) : null}
      {title && (
        <Typography component='p' variant='h1' sx={{ mb: subtitle ? 1 : 3 }}>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography component='p' variant='subtitle1' sx={{ mb: 3 }}>
          {subtitle}
        </Typography>
      )}
      <Box sx={{
        backgroundColor: 'common.white',
        borderRadius: '2px',
        border: '1px solid',
        borderColor: 'background.100',
        width: '100%',
        mb: 2.5
      }}>
        {children}
      </Box>
    </Box>
  )
}

PageLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  backButton: PropTypes.bool,
  onBack: PropTypes.func,
  children: PropTypes.node.isRequired
}

PageLayout.defaultProps = {
  onBack: emptyFn
}

export default PageLayout
