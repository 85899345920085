export const questions = [
  {
    title: 'How can this tool help me?',
    text: `The Word Prediction Pipeline (WPPL) web-app helps language specialists crawl the web
     for language data, sample that data, and produce word predictors for various languages.
      Word predictors can then be modified, tested, downloaded, and even imported into other applications.`
  },
  {
    title: 'What is a predictor?',
    text: `Within the context of the Word Prediction Pipeline (WPPL) web-app, a predictor is simply
     a special file containing all of the information required to perform word predictions in a given
      language. The following prediction types are supported: word completion, next-word prediction,
       auto-correction, and abbreviated expansion. The predictor file can be downloaded from this web-app
        and imported into any external application that has been integrated with the WPPL API.`
  },
  {
    title: 'What can I do with a predictor?',
    text: `A predictor enables an application to perform word predictions in a given language: word completion,
     next-word prediction, auto-correction, and abbreviated expansion. For example, if you have an external
      application called "Cool App" and you'd like to offer American English word predictions as a "Cool App"
       feature, you will need to create an American English predictor from the Word Prediction Pipeline (WPPL)
        web-app, test it, download it, and finally integrated it with your "Cool App". Then "Cool App" users will
         be able to benefit from the predictor's predictions!`
  },
  {
    title: 'How do I generate predictors?',
    text: `Generating predictors is a multi-step process. First you must generate language data samples from
     freely-available web pages online. The Word Prediction Pipeline (WPPL) web-app already has lots of data
      available that can be used to build predictors. If you want to gather new language samples, you must trigger
       a new web crawl. Assuming the samples that you need are already available, a new predictor can be built by
        clicking on the "Build Predictor" button from the "Predictors" tab on the dashboard. Then simply follow the
         flow to kick off your new build.`
  },
  {
    title: 'What is a sample?',
    text: `A sample is simply a text file containing normalized/ standardized language text from
     a particular URL freely accessible on the internet. The text from multiple samples is used to build predictors.`
  },
  {
    title: 'How do I generate samples?',
    text: `Generate samples by starting a new web crawl. Click on the "Start Crawl" button from the "Samples"
     tab on the dashboard. Then simply follow the flow to kick off your new web crawl.`
  },
  {
    title: 'What is a web crawl?',
    text: `When you start a web crawl, the Word Prediction Pipeline (WPPL) web-app initiates an
     algorithm to "crawl" across the internet, requesting large numbers of web pages along the way.
      Each web page is stripped of its raw language data (blocks of words, phrases, or sentences).
       The raw language data is then normalized to produce the language samples required by our predictors.`
  },
  {
    title: 'What is the task queue?',
    text: `Performing web crawls and building predictors is highly resource-intensive.
     As such, the Word Prediction Pipeline (WPPL) server can only handle one of these tasks at a time.
      When one or more users are using the WPPL web-app to perform more than one task, the resulting
       tasks must be executed sequentially. It's a first-come-first-serve kind of situation. If John
        wants to build a new predictor, but Sally is in the middle of a web craw, John must wait until
         the crawl is done before his task will execute. Fortunately, the WPPL web-app can keep track
          of pending tasks, so John can simply schedule his "Build Predictor" task and check on it later.`
  },
  {
    title: 'Can I create a predictor while a crawl is in progress?',
    text: `Yes, the Word Prediction Pipeline (WPPL) server will schedule your task for execution later
     once the crawl and any other pending tasks are completed.`
  },
]