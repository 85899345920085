import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Grid, Box, Typography } from '@mui/material'

import GlobalStore from '../../utils/store'
import { MenuIcon, GenericAvatar } from '../common'
import { PAGES, PREDICTOR_COPY, PREDICTOR_DOWNLOAD, POST, GET } from '../../constants'
import { downloadFile, emptyFn, fetchApi, PageContext, UserContext } from '../../utils/helpers'

const options = [
  { label: 'View', value: 'view' },
  { label: 'Edit', value: 'edit' },
  { label: 'Test', value: 'test' },
  { label: 'Duplicate', value: 'duplicate' },
  { label: 'Download', value: 'download' },
  { label: 'Merge with...', value: 'mergeWith' }
]

const sectionStyle = { display: 'flex', alignItems: 'center', justifyContent: 'end' }

/**
 * Note: Although the languages field is a list, predictor objects are associated with
 * exactly one language code. A list is used to allow for the possibility of producing
 * multi-language predictors in the future.
 **/
const PredictorRow = (props) => {
  const { setPage, handleErrorMsg } = useContext(PageContext)
  const { user } = useContext(UserContext)
  const {
    name,
    description,
    languages,
    size,
    created,
    handleDelete,
    image,
    user_initials,
    id,
    user_id
  } = props
  const displayDate = dayjs(created)
  const userOptions = user?.id === user_id
    ? [...options, { label: 'Delete', value: 'delete' }]
    : options

  const actions = {
    view: () => {
      GlobalStore.setData({ id }, PAGES.PREDICTOR_VIEW)
      setPage(PAGES.PREDICTOR_VIEW)
    },
    edit: () => {
      GlobalStore.setData({ id }, PAGES.PREDICTOR_EDIT)
      setPage(PAGES.PREDICTOR_EDIT)
    },
    test: () => {
      GlobalStore.setData({ id, name }, PAGES.PREDICTOR_TEST)
      setPage(PAGES.PREDICTOR_TEST)
    },
    duplicate: async () => {
      const result = await fetchApi(PREDICTOR_COPY(id), POST)
      if (result?.error) {
        handleErrorMsg(result?.error)
      }
    },
    download: async () => {
      const blob = await fetchApi(PREDICTOR_DOWNLOAD(id), GET, null, {
        'Content-Type': 'application/octet-stream',
        'Content-Length': size,
      })
      downloadFile(`${name}.wp`, blob)
    },
    mergeWith: () => {
      GlobalStore.setData({ id, languages, name }, PAGES.PREDICTOR_MERGE)
      setPage(PAGES.PREDICTOR_MERGE)
    },
    ...(user?.id === user_id ? { delete: () => handleDelete({ id, name }) } : {})
  }

  return (
    <Grid
      container
      sx={theme => ({
        width: '100%',
        height: 80,
        display: 'flex',
        alignItems: 'center',
        p: theme.spacing(0, 2),
        justifyContent: 'space-between',
        backgroundColor: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.background[100]
        }
      })}
    >
      <Grid item xs={4} sx={{ ...sectionStyle,  justifyContent: 'start' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 64,
            minHeight: 64,
            borderRadius: '2px',
            backgroundColor: 'grey.100',
            mr: 2
          }}
        >
          <Typography
            component='p'
            variant='body2'
            sx={{ color: 'common.white' }}
          >
            {languages?.length ? languages[0] : 'No-lang'}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Typography
            component='p'
            variant='subtitle2'
            sx={{ color: 'secondary.main' }}
          >
            {name}
          </Typography>
          <Typography
            component='p'
            variant='subtitle1'
            sx={{
              color: 'grey.300',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}
          >
            {description}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} sx={sectionStyle}>
        <Typography
          component='p'
          variant='subtitle1'
          sx={{ color: 'secondary.main', mr: 6 }}
        >
          {`${parseInt(size / 1e+6, 10)} MB`}
        </Typography>
        <Typography
          component='p'
          variant='subtitle1'
          sx={{ color: 'grey.300', mr: 2 }}
        >
          {`Created ${displayDate.isValid() ? displayDate.locale('en').format('MMM D') : '-'}`}
        </Typography>
        <GenericAvatar
          initials={user_initials || ''}
          src={image || ''}
          id={user_id}
        />
        <MenuIcon
          sx={{
            width: 32,
            height: 32,
            borderRadius: 0.5,
            '&:hover': {
              border: '1px solid',
              borderColor: 'grey.300',
              backgroundColor: 'transparent'
            }
          }}
          actions={actions}
          options={userOptions}
        >
          <MoreHorizIcon
            fontSize='small'
            sx={{ color: 'grey.300' }}/>
        </MenuIcon>
      </Grid>
    </Grid>
  )
}

PredictorRow.propTypes = {
  name: PropTypes.string,
  languages: PropTypes.array,
  description: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  username: PropTypes.string,
  user_initials: PropTypes.string,
  user_id: PropTypes.number,
  id: PropTypes.number,
  image: PropTypes.string,
  created: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleDelete: PropTypes.func
}

PredictorRow.defaultProps = {
  languages: [],
  handleDelete: emptyFn
}

export default PredictorRow
