import React, { useState, useContext, useEffect, useRef } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Box,
  Toolbar,
  Container
} from '@mui/material'

import DraggableDropdownList from './DraggableDropdownList'
import MenuIcon from './MenuIcon'
import GenericAvatar from './GenericAvatar'
import { Logo, PAGES, TASKS } from '../../constants'
import { PageContext, UserContext, fetchApi } from '../../utils/helpers'

const options = [{ label: 'Help', value: 'help' }, { label: 'Log Out', value: 'logout' }]

const AppMenu = () => {
  const {
    setPage,
    setInfoQueue,
    taskCreated,
    setTaskCreated
  } = useContext(PageContext)
  const { user, doLogout } = useContext(UserContext)
  const [menuOpen, setMenuOpen] = useState(false)
  const [tasks, setTasks] = useState([])
  const [startedTask, setStartedTask] = useState(null)
  const interval = useRef(null)

  const actions = {
    help: () => {
      setPage(PAGES.HELP)
    },
    logout: () => doLogout()
  }

  useEffect(() => {
    fetchTasks()
    interval.current = setInterval(fetchTasks, 3000)

    return () => {
      clearInterval(interval?.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskCreated])

  const fetchTasks = async () => {
    if (user) {
      const result = await fetchApi(TASKS)
      if (!result?.error) {
        const runningTask = result.find(el => el?.status === 'started')
        setTasks(result.filter(el => el?.status !== 'started'))
        setStartedTask(runningTask)
        if (taskCreated && result?.length >= 100) {
          setInfoQueue(true)
          setTaskCreated(false)
        }
      }
    }
  }

  return (
    <Container maxWidth='xl' sx={{
      '&.MuiContainer-root': {
        pl: 5,
        pr: 4.5,
        height: '100%',
        maxWidth: '100%'
      }
    }}>
      <Toolbar disableGutters sx={{
        display: 'flex',
        justifyContent: 'space-between',
        '&.MuiToolbar-root': {
          height: '100%'
        }
      }}>
        <Box
          component='div'
          sx={{mr: 2, display: 'flex', alignItems: 'center'}}
        >
          <Logo/>
        </Box>
        {(tasks && tasks?.length) || startedTask ? (
          <DraggableDropdownList queueTasks={tasks} startedTask={startedTask} />)
          : null
        }
        <Box sx={{flexGrow: 0}}>
          <MenuIcon
            sx={{p: 0}}
            actions={actions}
            options={options}
            onChange={setMenuOpen}
          >
            <GenericAvatar
              src={user?.image || ''}
              initials={user?.initials}
              sxAvatar={{ mr: 0.5 }}
              id={user?.id}
            />
            <KeyboardArrowDownIcon
              fontSize='small'
              sx={{
                color: 'common.white',
                ...(menuOpen ? { transform: 'rotate(180deg)' } : {})
              }}
            />
          </MenuIcon>
        </Box>
      </Toolbar>
    </Container>
  )
}

export default AppMenu
