import React from 'react'
import PropTypes from 'prop-types'
import { Draggable } from 'react-beautiful-dnd'
import { useTheme } from '@mui/material/styles'

import TaskListElement from './TaskListElement'

const DraggableTask = (props) => {
  const { draggableId, index, ...otherProps } = props
  const theme = useTheme()

  const getDraggedItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggable
    ...draggableStyle,
    // change background colour if dragging
    borderColor: isDragging ? theme?.palette?.grey[300] : theme?.palette?.grey[400],
    boxShadow: isDragging ? theme?.shadows[theme?.shadows?.length - 1] : 'none'
  })

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <TaskListElement
          ref={provided?.innerRef}
          {...(provided?.draggableProps || {})}
          {...(provided?.dragHandleProps || {})}
          style={getDraggedItemStyle(
            snapshot?.isDragging,
            provided?.draggableProps?.style
          )}
          {...(otherProps || {})}
        />
      )}
    </Draggable>
  )
}

DraggableTask.propTypes = {
  status: PropTypes.string,
  id: PropTypes.string,
  user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  user_initials: PropTypes.string,
  method: PropTypes.string,
  draggableId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func
}

export default DraggableTask
