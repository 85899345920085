import React from 'react'
import reduce from 'lodash/reduce'
import { Typography } from '@mui/material'

import palette from '../gatsby-theme-material-ui-top-layout/palette'

export const createCrawlerText = (content = '') => {
  const textRows = content.split('\n')
  return (<>
    {
      textRows.map((el, idx) => {
        if (el?.match('url')) {
          const strings = el?.split(': ')
          return (
            <Typography component='p' variant='body1'>
              {`${strings[0]}: `}<a
              href={strings[1]}
              target='_blank'
              style={{color: palette.primary.main}}
              rel='noreferrer'
            >
              {strings[1]}
              </a>
            </Typography>
          )
        }

        return (<Typography key={`content-${idx}`} component='p' variant='body1'>{el}</Typography>)
      })
    }
  </>)
}

export const languagesTooltipContent = (languages) => languages.map((el, idx) => (
  <Typography
    key={`language-${idx}`}
    variant='subtitle1'
    component='p'
    sx={{ p: 0, m: 0, color: 'common.white' }}
  >
    {el}
  </Typography>)
)

export const processCrawlSamples = (crawls) => {
  let domains = []
  const crawlsDomains = crawls.map(el => ({ domain: el?.domain, url: el?.url }))

  domains = reduce(crawlsDomains,
    (result, value) => {
      const resDomain = result?.find(el => el?.domain === value?.domain)

      if (resDomain) {
        resDomain?.urls.push(value?.url)
      } else {
        result.push({
          domain: value?.domain,
          urls: [value?.url]
        })
      }

      return result
    }, [])

  return domains
}