import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
  // Checkbox
} from '@mui/material'

const DetailsRow = (props) => {
  const { label, formatter } = props //value, checked, onChange,

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        px: 6.25,
        '&:hover': {
          backgroundColor: 'background.100'
        }
      }}
    >
      {/*<Checkbox*/}
      {/*  color='secondary'*/}
      {/*  value={value}*/}
      {/*  checked={checked}*/}
      {/*  onChange={onChange}*/}
      {/*/>*/}
      {
        formatter ? formatter(label) :(
          <Typography component='p' variant='subtitle1'>{label}</Typography>
        )
      }
    </Box>
  )
}

DetailsRow.propTypes = {
  label: PropTypes.string,
  formatter: PropTypes.func,
  // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // checked: PropTypes.bool,
  // onChange: PropTypes.func
}

export default DetailsRow